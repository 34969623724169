import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_VEHICLE_INDEX = 'ENTITY_VEHICLE_INDEX'
const ENTITY_VEHICLE_INDEX_SUCCESS = 'ENTITY_VEHICLE_INDEX_SUCCESS'
const ENTITY_VEHICLE_INDEX_FAIL = 'ENTITY_VEHICLE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_VEHICLE_INDEX, 'get', '/vehicles')
}

export function searchAll(query)
{
    return request(ENTITY_VEHICLE_INDEX, 'post', '/vehicles/search', { query })
}

const ENTITY_VEHICLE_RETRIEVE = 'ENTITY_VEHICLE_RETRIEVE'

export function fetchVehicle(id)
{
    return request(ENTITY_VEHICLE_RETRIEVE, 'get', `/vehicles/${id}`)
}

const ENTITY_VEHICLE_CREATE = 'ENTITY_VEHICLE_CREATE'

export function createVehicle(data)
{
    return request(ENTITY_VEHICLE_CREATE, 'post', '/vehicles', data)
}

const ENTITY_VEHICLE_UPDATE = 'ENTITY_VEHICLE_UPDATE'

export function updateVehicle(id, data)
{
    return request(ENTITY_VEHICLE_UPDATE, 'patch', `/vehicles/${id}`, data)
}


const ENTITY_VEHICLE_DELETE = 'ENTITY_VEHICLE_DELETE'

export function deleteVehicle(id)
{
    return request(ENTITY_VEHICLE_DELETE, 'delete', `/vehicles/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityVehicleReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_VEHICLE_INDEX:
            return {...state, loading: true}
        case ENTITY_VEHICLE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_VEHICLE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}