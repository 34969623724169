import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment';
import Modal from "../../../components/Modal";

import {updateCommand} from "../../../data/entities/Command";

class CommandUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: this.props.data.name,
        delivery_date: moment(this.props.data.delivery_date),
        address: this.props.data.address,
        success_delivery_picture: this.props.data.success_delivery_picture,
        comments: this.props.data.comments,
        delivery_position: moment(this.props.data.delivery_position),
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.updateCommand(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        return (
            <Modal title={this.props.data.id} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="Nom" label="Nom" type="text"/>

					<DatePicker
					    value={this.state.delivery_date} onChangeValue={(delivery_date) => this.setState({ delivery_date })}
					    placeholder="delivery_date" label="delivery_date" type="datetime"/>
					<Input
					    value={this.state.address} onChangeValue={(address) => this.setState({ address })}
					    placeholder="address" label="address" type="text"/>

					<FilePicker
					    name="success_delivery_picture" id="success_delivery_picture" value={this.state.success_delivery_picture}
					    onChangeValue={(success_delivery_picture) => this.setState({ success_delivery_picture })} />
					<Input
					    value={this.state.comments} onChangeValue={(comments) => this.setState({ comments })}
					    placeholder="comments" label="comments" type="text"/>

					<DatePicker
					    value={this.state.delivery_position} onChangeValue={(delivery_position) => this.setState({ delivery_position })}
					    placeholder="delivery_position" label="delivery_position" type="datetime"/>

                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updateCommand })(CommandUpdateModal)
