import React, {Component} from 'react';
import {fetchAllReadOnly} from "../data/entities/Command";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import moment from "moment";

class ScheduleReadOnly extends Component
{
    state = {
        data: null,
        page: 0,
        maxPages: 0,
        loading: false
    };

    componentDidMount() {
        this.loadCommands(0)
    }

    loadCommands = (page) => {
        this.setState({loading: true});
        this.props.fetchAllReadOnly(page).then((res) => {
            let data = res.payload.data;
            this.setState({ data: data.commands, maxPages: data.maxPages, loading: false })
        });
    };

    handlePageClick(page) {
        this.loadCommands(page);
        this.setState({ page });
    }

    render() {
        const { data, loading } = this.state;

        if (!data || loading)
            return this.renderLoading();

        return (
            <div className={'my-5'}>
                <div className="pagination my-5">
                    {this.renderPagination()}
                </div>

                {data.map((command) => this.renderRow(command))}

                <div className="pagination my-5">
                    {this.renderPagination()}
                </div>
            </div>
        )
    }

    renderRow(data) {
        return (
            <>
                <div className={'mb-2 mt-2'}>
                    <span className="font-weight-bold ml-3 mr-2">Nom:</span> <span style={{ fontSize: '15px'}}>{data.name}</span>
                    <span className="font-weight-bold ml-3 mr-2">Horaire:</span> <span style={{ fontSize: '15px'}}>{moment(data.delivery_position).format('H:mm DD/MM/YYYY')}</span>
                    <span className="font-weight-bold ml-3 mr-2">Destinataire:</span> <span style={{ fontSize: '15px'}}>{data.recipient_name}</span>
                </div>

                <div className={'mb-2'}>
                    <span className="font-weight-bold ml-3 mr-2">Adresse:</span> <span style={{ fontSize: '15px'}}>{data.address} {data.zipcode}</span>
                    <span className="font-weight-bold ml-3 mr-2">Contact:</span> <span style={{ fontSize: '15px'}}>{data.recipient_contact}</span>
                    <span className="font-weight-bold ml-3 mr-2">Téléphone:</span> <span style={{ fontSize: '15px'}}>{data.recipient_phone}</span>
                    <span className="font-weight-bold ml-3 mr-2">Email:</span> <span style={{ fontSize: '15px'}}>{data.recipient_email}</span>
                </div>

                <div className="mb-5">
                    <span className="font-weight-bold ml-3 mr-2">Statut:</span> <span className={'p-1'} style={{ fontSize: '15px', backgroundColor: data.command_status.color, color: 'white', borderRadius: '3px' }}>{data.command_status.name}</span>
                </div>
            </>
        )
    }

    renderLoading = () => {
        return <div className="text-center p-5">
            <Loader display={true}/>
        </div>
    };

    renderPagination() {
        let pages = [];

        for (let i = 0; i < this.state.maxPages; i++)
            pages.push(
                <div key={i}
                     className={this.state.page === i ? 'page selected' : 'page'}
                     onClick={() => this.handlePageClick(i)}
                >
                    {i + 1}
                </div>
            );

        return (pages);
    }
}

export default connect(null, {fetchAllReadOnly})(ScheduleReadOnly);
