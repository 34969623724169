import React, {Component} from 'react';

export default class Loader extends Component {
    render() {
        if (!this.props.display) return null
        return (
            <i className="fa fa-circle-notch fa-spin"/>
        )
    }
}

Loader.defaultProps = {
    display: true
}