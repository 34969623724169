import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_ORDER_STATUS_INDEX = 'ENTITY_ORDER_STATUS_INDEX'
const ENTITY_ORDER_STATUS_INDEX_SUCCESS = 'ENTITY_ORDER_STATUS_INDEX_SUCCESS'
const ENTITY_ORDER_STATUS_INDEX_FAIL = 'ENTITY_ORDER_STATUS_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ORDER_STATUS_INDEX, 'get', '/order_statuses')
}

export function searchAll(query)
{
    return request(ENTITY_ORDER_STATUS_INDEX, 'post', '/order_statuses/search', { query })
}

const ENTITY_ORDER_STATUS_RETRIEVE = 'ENTITY_ORDER_STATUS_RETRIEVE'

export function fetchOrderStatus(id)
{
    return request(ENTITY_ORDER_STATUS_RETRIEVE, 'get', `/order_statuses/${id}`)
}

const ENTITY_ORDER_STATUS_CREATE = 'ENTITY_ORDER_STATUS_CREATE'

export function createOrderStatus(data)
{
    return request(ENTITY_ORDER_STATUS_CREATE, 'post', '/order_statuses', data)
}

const ENTITY_ORDER_STATUS_UPDATE = 'ENTITY_ORDER_STATUS_UPDATE'

export function updateOrderStatus(id, data)
{
    return request(ENTITY_ORDER_STATUS_UPDATE, 'patch', `/order_statuses/${id}`, data)
}


const ENTITY_ORDER_STATUS_DELETE = 'ENTITY_ORDER_STATUS_DELETE'

export function deleteOrderStatus(id)
{
    return request(ENTITY_ORDER_STATUS_DELETE, 'delete', `/order_statuses/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityOrderStatusReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ORDER_STATUS_INDEX:
            return {...state, loading: true}
        case ENTITY_ORDER_STATUS_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ORDER_STATUS_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}