import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import BMap from '../../../components/BMap';
import {fetchAll, searchAll, getlatest} from '../../../data/entities/Geolocational'


class GeolocationalIndexPage extends Component {
    state = {
        search: '',
        showGeolocationalCreateModal: false,
        latest: [],
        position: [48.923798, 2.345888]
    };

    _search = (search) => {
        this.setState({search}, () => {
            this._fetch()
        })
    };

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    };

    refreshMapInfo = () => {
        this.props.getlatest().then((r) => {
            console.log('Map data refreshed', r.payload.data);
            this.setState({latest: r.payload.data});
        });
    };

    componentDidMount() {
        this.refreshMapInfo();
        this.setState({interval: setInterval(this.refreshMapInfo, 600000)});
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Position des livreurs</title>
                </Helmet>
                <div className="row">
                    <div className='col-10'>
                        <div style={{height: 'calc(100vh - 108px)', overflow: 'hidden'}}>
                            <BMap
                                position={this.state.position}
                                markers={this.state.latest}
                                renderPath={false}
                                onUpdateRequest={this.refreshMapInfo}
                            />
                        </div>
                    </div>
                    <div className={'col-2'}>
                        <table className='table table-hover' style={{marginLeft: '-12px'}}>
                            <thead>
                                <tr>
                                    <th>Liste des livreurs</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    { this.state.latest.map(l => this.renderUserRow(l)) }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    goToPosition = (position) => {
        // Si on setState juste, pas de willReeiveprops si on clique deux fois.
        // C'est un shitty lifehack mais ça marche
        this.setState({position: [position[0] + 0.000001, position[1]]},
            () => this.setState({position}));
    };

    renderUserRow = (location) => {
        const user = location.user;
        return <td key={user.id} className='d-flex link-like hover-parent' onClick={() => this.goToPosition([location.latitude, location.longitude])}>
            <img src='https://img.icons8.com/office/30/000000/person-male.png' width='28' height='28' className='mr-2 rounded-circle'/>
            <span>{user.name}</span>
            <span className='ml-auto d-none show-on-any-parent-hover'><i className='fa fa-location-arrow'/></span>
        </td>
    }
}

export default connect(({geolocational_entity}) => ({geolocational_entity}), {
    fetchAll,
    searchAll,
    getlatest
})(GeolocationalIndexPage)
