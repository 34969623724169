import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_ROLE_INDEX = 'ENTITY_ROLE_INDEX'
const ENTITY_ROLE_INDEX_SUCCESS = 'ENTITY_ROLE_INDEX_SUCCESS'
const ENTITY_ROLE_INDEX_FAIL = 'ENTITY_ROLE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ROLE_INDEX, 'get', '/roles')
}

export function searchAll(query)
{
    return request(ENTITY_ROLE_INDEX, 'post', '/roles/search', { query })
}

const ENTITY_ROLE_RETRIEVE = 'ENTITY_ROLE_RETRIEVE'

export function fetchRole(id)
{
    return request(ENTITY_ROLE_RETRIEVE, 'get', `/roles/${id}`)
}

const ENTITY_ROLE_CREATE = 'ENTITY_ROLE_CREATE'

export function createRole(data)
{
    return request(ENTITY_ROLE_CREATE, 'post', '/roles', data)
}

const ENTITY_ROLE_UPDATE = 'ENTITY_ROLE_UPDATE'

export function updateRole(id, data)
{
    return request(ENTITY_ROLE_UPDATE, 'patch', `/roles/${id}`, data)
}


const ENTITY_ROLE_DELETE = 'ENTITY_ROLE_DELETE'

export function deleteRole(id)
{
    return request(ENTITY_ROLE_DELETE, 'delete', `/roles/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityRoleReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ROLE_INDEX:
            return {...state, loading: true}
        case ENTITY_ROLE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ROLE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}