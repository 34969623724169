import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import Modal from "../../../components/Modal";

import {updateUser} from "../../../data/entities/User";
import {fetchAll} from "../../../data/entities/Role";

class UserUpdateModal extends Component {
    state = {
        _formData: null,
        _loading: true,
        _roles: [{id: 0, name: 'Chargement..'}],
        phone: this.props.data.phone,
        firstname: this.props.data.firstname,
        lastname: this.props.data.lastname,
        email: this.props.data.email,
        role: this.props.data.role,
        business_name: this.props.data.business_name,
        zipcode: this.props.data.zipcode,
        city: this.props.data.city,
        delivery_address: this.props.data.delivery_address,
        billing_address: this.props.data.billing_address,
    };

    _submit = () => {
        this.setState({_loading: true})

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.updateUser(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message}/>)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({_formData: e.error.response.data})
                this.setState({_loading: false})
            })
    }

    componentDidMount() {
        this.props.fetchAll().then((r) => {
            this.setState({_roles: r.payload.data, _loading: false})
        })
    }

    render() {
        return (
            <Modal title={"Modification de l'utilisateur #" + this.props.data.id}
                   onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <Input
                        value={this.state.phone} onChangeValue={(phone) => this.setState({phone})}
                        placeholder="Téléphone" label="Téléphone" type="text"/>

                    <Input
                        value={this.state.email} onChangeValue={(email) => this.setState({email})}
                        placeholder="Email" label="Email" type="email"/>

                    <Input
                        value={this.state.password} onChangeValue={(password) => this.setState({password})}
                        placeholder="Nouveau mot de passe (Laisser vide pour ne rien faire)"
                        label="Nouveau mot de passe" type="password"/>

                    {this.renderRoles(this.state._roles)}

                    <Input
                        value={this.state.firstname} onChangeValue={(firstname) => this.setState({firstname})}
                        placeholder="Prénom" label="Prénom" type="text"/>

                    <Input
                        value={this.state.lastname} onChangeValue={(lastname) => this.setState({lastname})}
                        placeholder="Nom" label="Nom" type="text"/>

                    <Input
                        value={this.state.zipcode} onChangeValue={(zipcode) => this.setState({zipcode})}
                        placeholder="Code postal" label="Code postal" type="text"/>

                    <Input
                        value={this.state.city} onChangeValue={(city) => this.setState({city})}
                        placeholder="Ville" label="Ville" type="text"/>


                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>
            </Modal>
        )
    }

    renderRoles = (roles) => {
        const jsxs = roles.map((r) => {
            return (<option key={r.id} value={r.id}>{r.name}</option>)
        });

        return <div className="form-group">
            <label htmlFor="role-select">Role :</label>
            <select value={this.state.role} id="role-select" className="form-control" onChange={(e) => this.setState({role: e.target.value})}>
                {jsxs}
            </select>
        </div>;
    }
}

export default connect(null, {updateUser, fetchAll})(UserUpdateModal)
