import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment-timezone';
import '../../../styles/pagination.scss';
import '../../../styles/notifications.scss'

import {fetchByPage} from '../../../data/entities/Notification'

class NotificationIndexPage extends Component
{
    state = {
        showNotificationCreateModal: false,
        maxPages: 0,
        page: 0,
        notifications: []
    };

    componentDidMount() {
        this.fetchPage(0);
    }

    fetchPage(page) {
        this.props.fetchByPage(page)
            .then((res) => {
                let data = res.payload.data;

                this.setState({
                    notifications: data.notifications,
                    maxPages: data.maxPages
                })
            })
            .catch(err => console.error(err));
    }

    handlePageClick(page) {
        this.fetchPage(page);
        this.setState({ page });
    }

    render() {
        return (
            <div className="container notifications_page">
                <Helmet>
                    <title>Notification</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <h1>Notification</h1>
                    </div>
                </div>
                {this.renderEntities()}
                <div className="pagination">
                    {this.renderPagination()}
                </div>
            </div>
        )
    }

    renderPagination() {
        let pages = [];

        for (let i = 0; i < this.state.maxPages; i++)
            pages.push(
                <div key={i}
                     className={this.state.page === i ? 'page selected' : 'page'}
                     onClick={() => this.handlePageClick(i)}
                >
                    {i + 1}
                </div>
            );

        return (pages);
    }

    renderEntities()
    {
        return (
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        <th>Utilisateur</th>
                        <th>Message</th>
                        <th>Date de reception</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.notifications.map((entity, i) => (
                            <tr key={i}>
                                <td>{ entity.user } </td>
                                {/*<td>{ entity.message }</td>*/}
                                <td dangerouslySetInnerHTML={{__html: entity.message}} />
                                <td>{ moment(entity.created_at).tz("Europe/Paris").format('DD-MM-YYYY HH:mm') }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }
}

export default connect(({notification_entity}) => ({notification_entity}), { fetchByPage })(NotificationIndexPage)
