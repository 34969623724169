const locale = {
    // Validator
    "validator.form-error": "Veuillez vérifier le formulaire.",
    "validator.email": "Cette adresse e-mail est invalide.",
    "validator.email.unknown": "Adresse e-mail inconnue.",
    "validator.email.taken": "Adresse e-mail déjà utilisée.",
    "validator.min_length": "Trop court.",
    "validator.max_length": "Trop long.",
    // Validator (custom)
    "validator.form-error:auth.login": "Ces identifiants sont incorrects.",
    "validator.min_length:password:auth.register": "Ce mot de passe est trop court (min. 6 caractères).",
    //"validator.email.unknown:auth.forgot_password": "Adresse e-mail inconnue.",

    //

    // ...
}

export default locale