import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import MaterialCreateModal from "./MaterialCreateModal";

import {fetchAll, searchAll, deleteMaterial} from '../../../data/entities/Material'
import MaterialDeleteModal from "./MaterialDeleteModal";

class MaterialIndexPage extends Component
{
    state = {
        search: '',
        showMaterialCreateModal: false,
        showMaterialDeleteModal: false,
        entity: null
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    toggleDeleteModal(entity = null) {
        if (entity)
            this.setState({ entity, showMaterialDeleteModal: true })
        else
            this.setState({ entity: null, showMaterialDeleteModal: false })
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Matériel</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <h1>Matériel</h1>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.material_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button className="ml-3" onClick={() => this.setState({ showMaterialCreateModal: true })}><i className='fa fa-plus mr-2'/> Ajouter un matériel</button>
                    </div>
                </div>
                {this.renderEntities()}
                {this.state.showMaterialCreateModal ? (
                    <MaterialCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showMaterialCreateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showMaterialDeleteModal ? (
                    <MaterialDeleteModal
                        data={this.state.entity}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.setState({ showMaterialDeleteModal: false }, () => this._fetch())
                            }
                            else this.setState({ showMaterialDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }

    renderEntities()
    {
        if (this.props.material_entity.loading && !this.props.material_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.material_entity.index.length) {
            return <Empty/>
        }

        return (
            <table className="table table-striped mt-3">
                <tbody>
                {
                    this.props.material_entity.index.map((entity, i) => (
                        <tr key={i}>
                            <td>
                                <Link to={"/entities/material/" + entity.id}>Matériel n°{entity.id} - {entity.name}</Link>
                            </td>
                            <td className='text-right'>
                                <button onClick={() => this.toggleDeleteModal(entity)}><i className='fa fa-trash mr-2'/> Supprimer</button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        )
    }
}

export default connect(({ material_entity }) => ({ material_entity }), { fetchAll, searchAll, deleteMaterial })(MaterialIndexPage)
