import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {fetchMaterial} from '../../../data/entities/Material'
import MaterialUpdateModal from "./MaterialUpdateModal";
import MaterialDeleteModal from "./MaterialDeleteModal";

class MaterialViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showMaterialUpdateModal: false,
        showMaterialDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchMaterial(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/material')
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Matériel #{this.state.id}</title>
                </Helmet>
                <h1>Matériel #{this.state.id}</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div>
                <p>Nom du matériel : {this.state.data.name}</p>
                <p>Actions :</p>
                <p>
                    <button
                        onClick={() => this.setState({ showMaterialUpdateModal: true })}
                        className="btn btn-primary">Modifier</button>
                    <button
                        onClick={() => this.setState({ showMaterialDeleteModal: true })}
                        className="btn btn-danger ml-2">Supprimer</button>
                </p>
                {this.state.showMaterialUpdateModal ? (
                    <MaterialUpdateModal
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showMaterialUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showMaterialDeleteModal ? (
                    <MaterialDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/material')
                            }
                            else this.setState({ showMaterialDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ material_entity }) => ({ material_entity }), { fetchMaterial })(MaterialViewPage)
