import React, {Component} from 'react';


export default class Accordion extends Component {

    render() {
        return <div id={this.props.id} className="mb-2">
            <div className="card">
                <div className="card-header hover-cursor-pointer" id={'heading-' + this.props.id} data-toggle="collapse" data-target={'#collapse-' + this.props.id}>
                    <h5 className="mb-0">
                        {this.props.title}
                    </h5>
                </div>
                <div id={'collapse-' + this.props.id} className="collapse" data-parent={'#' + this.props.id}>
                    <div className="card-body">
                        <table className='table table-striped'>
                            <tbody>
                                {this.renderdata()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }

    renderdata = () => {
        return this.props.data.map((d, i) => {
            return <tr key={'tr-' + i}>
                { d.map( (dd, i) => <td key={'td-' + i}>{dd}</td>) }
            </tr>
        });
    };

    renderRow = (dd) => {
        return dd.map(d => <td>{d}</td>);
    }
}

Accordion.defaultProps = {
    data: [],
    id: 'accordion-' + Math.floor(Math.random() * 1000),
    title: 'Voir plus'
};
