import React, { Component } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {IntlProvider, addLocaleData, FormattedMessage} from "react-intl";
import { ToastContainer } from 'react-toastify';
import {authTick} from "./data/OAuth";
import {connect} from 'react-redux';
import {FormTranslationContext} from 'react-forms'


import store from "./config/redux";
import routes from "./config/routes";
import RouteHandler from './RouteHandler';

//

import 'jquery';
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.scss'
import {fetchProfile} from "./data/User";

//

import locale_fr from 'react-intl/locale-data/fr'
import messages_fr from './locale/fr'

addLocaleData([...locale_fr]);

const messages = {
    'fr': messages_fr
};

class AuthProviderUnconnected extends Component
{
    componentDidMount()
    {
        this.props.authTick()
    }

    componentWillReceiveProps(props)
    {
        if (props.oauth.access_token && !props.user.profile) {
            this.props.fetchProfile()
        }
    }

    render()
    {
        if (!this.props.oauth.ready)
            return (
                <p className="text-center text-muted">
                    <i className="fa fa-circle-notch fa-spin mt-5 mr-2"/>
                    <span>Identification</span>
                </p>
            )

        if ((this.props.oauth.access_token && !this.props.user.profile))
            return (
                <p className="text-center text-muted">
                    <i className="fa fa-circle-notch fa-spin mt-5 mr-2"/>
                    <span>Récupération du profil</span>
                </p>
            )

        return this.props.children
    }
}

const AuthProvider = connect(({ oauth, user }) => ({ oauth, user }), { authTick, fetchProfile })(AuthProviderUnconnected)

const locale = 'fr';

export default class App extends Component
{
    render()
    {
        return (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <FormTranslationContext.Provider value={{
                    renderText: (text) => {
                        const parts = text.split(':')
                        if (!messages[locale][text]) {
                            if (parts.length === 3 && messages[locale][parts[0] + ':' + parts[2]]) {
                                return <FormattedMessage id={parts[0] + ':' + parts[2]} />
                            }
                            if (parts.length >= 2 && messages[locale][parts[0] + ':' + parts[1]]) {
                                return <FormattedMessage id={parts[0] + ':' + parts[1]} />
                            }
                            return <FormattedMessage id={parts[0]} />
                        }
                        return <FormattedMessage id={text} />
                    }
                }}>
                    <Provider store={store}>
                        <AuthProvider>
                            <Router>
                                <Switch>
                                    {routes.map(({ path, exact, page: Component, layout, props, security }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            exact={exact}
                                            render={(renderProps) => (
                                                <RouteHandler {...renderProps} security={security} layout={layout} routeProps={props}>
                                                    <Component {...{...props, ...renderProps}} />
                                                </RouteHandler>
                                            )}
                                        />
                                    ))}
                                </Switch>
                            </Router>
                        </AuthProvider>
                        <ToastContainer />
                    </Provider>
                </FormTranslationContext.Provider>
            </IntlProvider>
        )
    }
}
