import React, {Component} from 'react';
import '../../styles/generalpurpose/pseudoclasscolors.scss'
import '../../styles/generalpurpose/hoverdisplayproperties.scss'
import {connect} from "react-redux";

class RBCEvent extends Component {
    state = {
    };

    render() {
        const data = this.props.event;

        return <div onClick={() => this.props.onRequestModal(data)}>
            <span>{this.props.title}</span>
            <span>{this.props.desc}</span>
        </div>
    }
}

RBCEvent.defaultProps = {
    onRequestModal: () => {}
};

export default connect(null, {})(RBCEvent)
