import Role from './Role';
import User from './User';
import Command from './Command';
import Product from './Product';
import Material from './Material';
import Notification from './Notification';
import Geolocational from './Geolocational';
import Vehicle from './Vehicle';
import OrderStatus from './OrderStatus';

const reducers = {
    role_entity: Role,
    user_entity: User,
    command_entity: Command,
    product_entity: Product,
    material_entity: Material,
    notification_entity: Notification,
    geolocational_entity: Geolocational,
    vehicle_entity: Vehicle,
    order_status_entity: OrderStatus,
}

export default reducers