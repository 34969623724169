import {OAUTH_LOGOUT} from "./OAuth";
import {request} from '../helpers'

// Actions

const USER_FETCH_PROFILE = 'USER_FETCH_PROFILE'
const USER_FETCH_PROFILE_SUCCESS = 'USER_FETCH_PROFILE_SUCCESS'
const USER_FETCH_PROFILE_FAIL = 'USER_FETCH_PROFILE_FAIL';
const USER_DELIVERYMAN_STATISTIQUES = 'USER_DELIVERYMAN_STATISTIQUES';

export function fetchProfile()
{
    return request(USER_FETCH_PROFILE, 'get', '/user')
}

export function getDeliveryManStatistiques()
{
    return (request(USER_DELIVERYMAN_STATISTIQUES, 'get', '/deliveryMan/statistiques'));
}

// Store

const defaultState = {
    profile: null
}

// Reducer

export default function UserReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case OAUTH_LOGOUT:
            return defaultState;
        case USER_DELIVERYMAN_STATISTIQUES:
            return {
                ...state,
                statistiques: action.payload.data
            }
        case USER_FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.data
            }
    }
    return state
}
