import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_COMMAND_INDEX = 'ENTITY_COMMAND_INDEX'
const ENTITY_COMMAND_INDEX_SUCCESS = 'ENTITY_COMMAND_INDEX_SUCCESS'
const ENTITY_COMMAND_INDEX_FAIL = 'ENTITY_COMMAND_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_COMMAND_INDEX, 'get', '/commands')
}

const ENTITY_COMMAND_INDEX_READONLY = 'ENTITY_COMMAND_INDEX_READONLY'
const ENTITY_COMMAND_INDEX_READONLY_SUCCESS = 'ENTITY_COMMAND_INDEX_READONLY_SUCCESS'
const ENTITY_COMMAND_INDEX_READONLY_FAIL = 'ENTITY_COMMAND_INDEX_READONLY_FAIL'

export function fetchAllReadOnly(page)
{
    return request(ENTITY_COMMAND_INDEX_READONLY, 'get', '/commands/read-only/' + page)
}

export function searchAll(query)
{
    return request(ENTITY_COMMAND_INDEX, 'post', '/commands/search', { query })
}

const ENTITY_COMMAND_RETRIEVE = 'ENTITY_COMMAND_RETRIEVE'

export function fetchCommand(id)
{
    return request(ENTITY_COMMAND_RETRIEVE, 'get', `/commands/${id}`)
}

const ENTITY_COMMAND_CREATE = 'ENTITY_COMMAND_CREATE'

export function createCommand(data)
{
    return request(ENTITY_COMMAND_CREATE, 'post', '/commands', data)
}

const ENTITY_COMMAND_UPDATE = 'ENTITY_COMMAND_UPDATE'

export function updateCommand(id, data)
{
    return request(ENTITY_COMMAND_UPDATE, 'patch', `/commands/${id}`, data)
}

const ENTITY_COMMAND_UPDATE_DELIVERY = 'ENTITY_COMMAND_UPDATE_DELIVERY'

export function updateCommandDelivery(id, data)
{
    return request(ENTITY_COMMAND_UPDATE_DELIVERY, 'patch', `/commands/${id}/update-delivery`, data)
}

const ENTITY_COMMAND_DELETE = 'ENTITY_COMMAND_DELETE'

export function deleteCommand(id)
{
    return request(ENTITY_COMMAND_DELETE, 'delete', `/commands/${id}`)
}

const ENTITY_COMMAND_GETFORDAY = 'ENTITY_COMMAND_GETFORDAY'

export function getCommandsForDay(day) {
    // Normalement c'est un GET, mais là on veut communiquer une date, alors je laisse comme ça pour le moment
    return request(ENTITY_COMMAND_GETFORDAY, 'POST', '/commands/getforday', {day});
}

export function getCommandsForWeek(day) {
    // Normalement c'est un GET, mais là on veut communiquer une date, alors je laisse comme ça pour le moment
    return request(ENTITY_COMMAND_GETFORDAY, 'POST', '/commands/weekly', {day});
}

const ENTITY_COMMAND_SENDCOMMENT = 'ENTITY_COMMAND_SENDCOMMENT'

export function submitCommandComment(command_id, comment) {
    return request(ENTITY_COMMAND_SENDCOMMENT, 'POST', '/commands/addcomment', {id: command_id, comment});
}

const ENTITY_COMMAND_SEND_MATERIAL_COMMENT = 'ENTITY_COMMAND_SEND_MATERIAL_COMMENT'

export function submitCommandMaterialComment(command_id, material_comment) {
    return request(ENTITY_COMMAND_SEND_MATERIAL_COMMENT, 'POST', '/commands/addcomment', {id: command_id, material_comment});
}

const ENTITY_COMMAND_UPDATE_VEHICLE = 'ENTITY_COMMAND_UPDATE_VEHICLE'

export function submitCommandVehicle(command_id, vehicle_id) {
    return request(ENTITY_COMMAND_UPDATE_VEHICLE, 'POST', '/commands/vehicle', {id: command_id, vehicle_id});
}

const ENTITY_COMMAND_INDEX_MATERIAL = 'ENTITY_COMMAND_INDEX_MATERIAL'

export function commandIndexMaterial(id) {
    return request(ENTITY_COMMAND_INDEX_MATERIAL, 'GET', `/commands/${id}/command_materials`);
}

const ENTITY_COMMAND_ADD_MATERIAL = 'ENTITY_COMMAND_ADD_MATERIAL'

export function commandAddMaterial(command_id, material_id, size, quantity) {
    return request(ENTITY_COMMAND_ADD_MATERIAL, 'PUT', '/commands/' + command_id + '/command_material/' + material_id, {
        size: size,
        quantity: quantity
    });
}

const ENTITY_COMMAND_DELETE_MATERIAL = 'ENTITY_COMMAND_DELETE_MATERIAL';

export function commandDeleteMaterial(command_id, material_id) {
    return request(ENTITY_COMMAND_DELETE_MATERIAL, 'DELETE', '/commands/' + command_id + '/command_material/' + material_id);
}

const ENTITY_COMMAND_CHANGEDELIVERYMAN = 'ENTITY_COMMAND_CHANGEDELIVERYMAN'

export function changeDeliveryman(type, command, deliveryman, position = null) {
    if (type === 'command')
        return request(ENTITY_COMMAND_CHANGEDELIVERYMAN, 'POST', '/commands/changeDeliveryman', {command, deliveryman, position});
    else if (type === 'commandmaterial')
        return request(ENTITY_COMMAND_CHANGEDELIVERYMAN, 'POST', '/command_materials/changeDeliveryman', {command, deliveryman});
    return null;
}

const ENTITY_COMMAND_UPDATE_POSITION = 'ENTITY_COMMAND_UPDATE_POSITION'

export function updatePosition(command, position) {
    return request(ENTITY_COMMAND_UPDATE_POSITION, 'POST', '/commands/position', {id: command, position});
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityCommandReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_COMMAND_INDEX:
        case ENTITY_COMMAND_INDEX_READONLY:
            return {...state, loading: true}
        case ENTITY_COMMAND_INDEX_FAIL:
        case ENTITY_COMMAND_INDEX_READONLY_FAIL:
            return {...state, loading: false}
        case ENTITY_COMMAND_INDEX_SUCCESS:
        case ENTITY_COMMAND_INDEX_READONLY_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
