import {ROLE_ANONYMOUS, ROLE_USER, ROLE_ADMIN} from "./security";

import BaseLayout from "../layouts/BaseLayout";

import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from "../pages/auth/LoginPage";
import HomePage from "../pages/HomePage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import SandboxPage from "../pages/SandboxPage";

import UserIndexPage from "../pages/entities/User/UserIndexPage";
import UserViewPage from "../pages/entities/User/UserViewPage";
import CommandIndexPage from "../pages/entities/Command/CommandIndexPage";
import CommandViewPage from "../pages/entities/Command/CommandViewPage";
import ProductIndexPage from "../pages/entities/Product/ProductIndexPage";
import ProductViewPage from "../pages/entities/Product/ProductViewPage";
import MaterialIndexPage from "../pages/entities/Material/MaterialIndexPage";
import MaterialViewPage from "../pages/entities/Material/MaterialViewPage";
import NotificationIndexPage from "../pages/entities/Notification/NotificationIndexPage";
import NotificationViewPage from "../pages/entities/Notification/NotificationViewPage";
import GeolocationalIndexPage from "../pages/entities/Geolocational/GeolocationalIndexPage";
import VehicleIndexPage from "../pages/entities/Vehicle/VehicleIndexPage";
import VehicleViewPage from "../pages/entities/Vehicle/VehicleViewPage";
import SchedulePage from "../pages/SchedulePage";
import StatistiquePage from '../pages/StatistiquePage';
import CalendarPage from "../pages/CalendarPage";
import ScheduleReadOnly from "../pages/ScheduleReadOnly";

const routes = [
    // Auth
    {
        path: '/auth/login',
        exact: true,
        page: LoginPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_ANONYMOUS]
        }
    },
    {
        path: '/auth/forgot-password',
        exact: true,
        page: ForgotPasswordPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_ANONYMOUS]
        }
    },
    //
    {
        path: '/',
        exact: true,
        page: HomePage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/sandbox',
        exact: true,
        page: SandboxPage,
        layout: BaseLayout
    },
    {
        path: '/order/schedule',
        exact: true,
        page: SchedulePage,
        layout: BaseLayout,
        /*security: {
            roles: [ROLE_ADMIN]
        }*/
    },
    {
        path: '/commands/calendar',
        exact: true,
        page: CalendarPage,
        layout: BaseLayout
    },
    {
      path: '/deliveryman/statistiques',
      exact: true,
      page: StatistiquePage,
      layout: BaseLayout
    },
    // Entities
    {
        path: '/entities/user',
        exact: true,
        page: UserIndexPage,
        layout: BaseLayout
    },
    {
        path: '/entities/user/:id',
        exact: true,
        page: UserViewPage,
        layout: BaseLayout
    },
    {
        path: '/entities/command',
        exact: true,
        page: CommandIndexPage,
        layout: BaseLayout,
        /*security: {
            roles: [ROLE_ADMIN]
        }*/
    },
    {
        path: '/entities/command/:id',
        exact: true,
        page: CommandViewPage,
        layout: BaseLayout,
        /*security: {
            roles: [ROLE_ADMIN]
        }*/
    },
    {
        path: '/entities/product',
        exact: true,
        page: ProductIndexPage,
        layout: BaseLayout
    },
    {
        path: '/entities/product/:id',
        exact: true,
        page: ProductViewPage,
        layout: BaseLayout
    },
    {
        path: '/entities/material',
        exact: true,
        page: MaterialIndexPage,
        layout: BaseLayout
    },
    {
        path: '/entities/material/:id',
        exact: true,
        page: MaterialViewPage,
        layout: BaseLayout
    },
    {
        path: '/entities/notification',
        exact: true,
        page: NotificationIndexPage,
        layout: BaseLayout
    },
    {
        path: '/entities/notification/:id',
        exact: true,
        page: NotificationViewPage,
        layout: BaseLayout
    },
    {
        path: '/entities/geolocational',
        exact: true,
        page: GeolocationalIndexPage,
        layout: BaseLayout,
        /*security: {
            roles: [ROLE_ADMIN]
        }*/
    },
    {
        path: '/entities/vehicle',
        exact: true,
        page: VehicleIndexPage,
        layout: BaseLayout
    },
    {
        path: '/entities/vehicle/:id',
        exact: true,
        page: VehicleViewPage,
        layout: BaseLayout
    },
    {
        path: '/order/schedule/read-only',
        exact: true,
        page: ScheduleReadOnly,
        layout: BaseLayout
    },
    // Misc
    {
        path: '/',
        exact: false,
        page: NotFoundPage,
        layout: BaseLayout
    }
];

export default routes
