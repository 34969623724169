import React, {Component} from 'react';
import {TextArea} from 'react-forms';
import Modal from '../Modal';
import {connect} from "react-redux";
import {
    submitCommandComment,
    submitCommandMaterialComment,
    commandAddMaterial,
    commandDeleteMaterial,
    commandIndexMaterial,
    submitCommandVehicle
} from '../../data/entities/Command';
import {fetchAll as fetchVehicles} from '../../data/entities/Vehicle';
import {fetchAll} from '../../data/entities/Material';
import {toast} from "react-toastify";
import Accordion from "../Accordion";
import CommandDeleteModal from "../../pages/entities/Command/CommandDeleteModal";
import DeliveryDatePicker from "./DeliveryDatePicker";

class TrelloCardEditModal extends Component {
    state = {
        updating: false,
        errors: null,

        vehicles: null,
        vehicle: this.props.data.vehicle,

        comments: this.props.data.comments,
        material_comments: this.props.data.material_comments,

        commandMaterial: null,
        selectedMaterial: "",
        materials: [],
        size: 'petit',
        quantity: 0,
        showCommandDeleteModal: false,
        delivery_position: this.props.data ? this.props.data.delivery_position : null
    };

    material_added = React.createRef();

    toggleMaterialAdded = () => {
        this.material_added.current.classList.remove('d-none');
        setTimeout(() => {
            this.material_added.current && this.material_added.current.classList.add('d-none');
        }, 3000);
    }

    toggleCommandDeleteModal = () => {
        this.setState({ showCommandDeleteModal: !this.state.showCommandDeleteModal });
    }

    componentDidMount() {
        this.props.fetchAll()
            .then((res) => {
                let data = res.payload.data;
                this.setState({
                    materials: data,
                    selectedMaterial: data[0].id
                })
            })
            .catch(err => console.error(err));

        this.fetchCommandMaterials();

        this.props.fetchVehicles()
            .then(res => {
                this.setState({ vehicles: res.payload.data })
            })
    }

    fetchCommandMaterials() {
        this.props.commandIndexMaterial(this.props.data.dbid)
            .then((res) => {
                let materials = [];
                res.payload.data.map((commandMaterial) => {
                    commandMaterial.materials.map((material) =>  {
                        materials.push(material)
                    })
                });

                this.setState({ commandMaterial: materials });
            })
            .catch(err => console.error(err));
    }

    render()
    {
        const {data} = this.props

        const delete_wrapper = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        };

        return (
            <Modal title={this.props.data.title} onClose={() => this.props.onClose({materials: this.state.commandMaterial, id: this.props.data.dbid})}>
                <table className="table">
                    <tr>
                        <th>Destinataire</th>
                        <td>{data.recipient_name}</td>
                    </tr>
                    <tr>
                        <th rowSpan={2}>Adresse</th>
                        <td>{data.address}</td>
                    </tr>
                    <tr>
                        <td>{data.zipcode}, {data.city}</td>
                    </tr>
                    <tr>
                        <th>Contact</th>
                        <td>{data.recipient_contact}</td>
                    </tr>
                    <tr>
                        <th>Téléphone</th>
                        <td>{data.recipient_phone}</td>
                    </tr>
                    <tr>
                        <th>Adresse e-mail</th>
                        <td>{data.customer_email}</td>
                    </tr>
                    { this.state.delivery_position &&
                    <tr>
                        <th>Horaire</th>
                        <td>
                            <DeliveryDatePicker
                                delivery_time={this.state.delivery_position}
                                data={this.props.data}
                                update={() => this.props.onUpdate()}
                            />
                        </td>
                    </tr>}
                    <tr>
                        <th>Véhicule</th>
                        <td>
                            {this.renderVehicle()}
                        </td>
                    </tr>
                    <tr>
                        <th>Commentaires</th>
                        <td>
                            <TextArea
                                value={this.state.comments}
                                onChangeValue={(comments) => this.setState({comments})}
                            />
                            <button onClick={() => this.onSubmitComment()}>Enregistrer</button>
                        </td>
                    </tr>
                    <tr>
                        <th>Notes sur le matériel</th>
                        <td>
                            <TextArea
                                value={this.state.material_comments}
                                onChangeValue={(material_comments) => this.setState({material_comments})}
                            />
                            <button onClick={() => this.onSubmitMaterialComment()}>Enregistrer</button>
                        </td>
                    </tr>
                </table>

                <hr />

                <div className="my-3">
                    {this.renderProducts(this.props.data.products)}
                </div>

                <hr />

                <div className='mt-3'>
                    {this.renderListMaterials()}
                </div>

                <div className="my-3">
                    <h6>Prêter du matériel</h6>
                    {this.renderCrudMaterial()}
                </div>

                { this.state.showCommandDeleteModal ?
                    <CommandDeleteModal
                        data={this.props.data ? {'id': this.props.data.dbid} : null}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.toggleCommandDeleteModal();
                                this.props.onDelete(deleted);
                            }
                            else this.toggleCommandDeleteModal();
                        }}
                    />
                    : null }
            </Modal>
        );
    }

    renderProducts = (products) => {
        if (this.props.data.type === 'commandmaterial')
            return '';

        const prods = products.map((m) => {
            return [m.name, m.quantity ? "x" + m.quantity : null];
        });

        let ret = <Accordion data={prods} id={'accordion-products'} title="Produits"/>;
        if (prods.length === 0)
            ret = <em>Aucun produit renseigné</em>;

        return (
            <div className={"mt-4"}>
                {ret}
            </div>
        );
    };

    renderCrudMaterial() {
        return (
            <div>
                <div className='input-group'>
                    <div className="form-group mr-1" style={{flex: 1}}>
                        <select className={"form-control"}
                                onChange={(e) => this.setState({selectedMaterial: e.target.value})}>
                            {this.state.materials.map(material => <option value={material.id}
                                                                          key={material.id}>{material.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group mr-1">
                        <select className={"form-control"} value={this.state.size}
                                onChange={(e) => this.setState({size: e.target.value})}>
                            <option value={'petit'}>Petit</option>
                            <option value={'grand'}>Grand</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <input type="number"
                               value={this.state.quantity}
                               onChange={(e) => this.setState({quantity: e.target.value})}
                               className={"form-control"}
                               min={0}
                        />
                    </div>
                </div>
                <button onClick={() => this.linkNewMaterial()}>Ajouter un matériel</button>
                <span ref={this.material_added} className={'float-right d-none'} style={{color: 'red'}}>Matériel ajouté !</span>
            </div>
        )
    }

    renderListMaterials() {
        const {commandMaterial} = this.state;

        if (!commandMaterial) return null;

        const mats = commandMaterial.map((m) => {
            let name = (m.customname) ? m.customname : m.name;
            let size = (m.size) ? 'Taille: ' + m.size : 'Taille non renseignée';
            let quantity = (m.quantity) ? 'Quantité: ' + m.quantity : 'Quantité non renseignée';
            const deleteIcon = <i className='fa fa-times hover-cursor-pointer' onClick={() => this.deleteMaterial(m.dbid)}/>

            return [name, size, quantity, deleteIcon];
        });

        let ret = <Accordion data={mats} id={'accordion-materials'} title="Matériel prêté"/>;
        if (mats.length === 0)
            ret = <em>Pas de matériel renseigné</em>;

        return (
            <div>
                { ret }
            </div>
        )
    }

    renderVehicle()
    {
        const {vehicle, vehicles} = this.state

        if (!vehicles)
            return <span>Chargement...</span>

        return (
            <>
                <i className="fas fa-car mr-2"/>
                <select
                    onChange={e => this.onSubmitVehicle(e.target.value)}
                    value={vehicle.id ? vehicle.id : "none"}>
                    <option value="none">Aucun</option>
                    {vehicles.map(v => (
                        <option value={v.id}>{v.name}</option>
                    ))}
                </select>
            </>
        )
    }

    deleteMaterial = (id) => {
        this.props.commandDeleteMaterial(this.props.data.dbid, id).then(() => {
            this.fetchCommandMaterials();
            this.props.onUpdate()
        });
    };

    linkNewMaterial = () => {
        const {selectedMaterial, size, quantity, commandMaterial} = this.state;

        this.props.commandAddMaterial(this.props.data.dbid, selectedMaterial, size, quantity)
            .then((res) => {
                commandMaterial.push(res.payload.data);
                this.setState({commandMaterial}, () => this.toggleMaterialAdded());
                this.props.onUpdate()
            })
            .catch(err => console.error(err));
    };

    onSubmitComment = () => {
        this.props.submitCommandComment(this.props.data.dbid, this.state.comments).then((r) => {
            toast.success("Votre commentaire à bien été mis à jour");
            this.props.onUpdate()
        });
    };

    onSubmitMaterialComment = () => {
        this.props.submitCommandMaterialComment(this.props.data.dbid, this.state.material_comments).then((r) => {
            toast.success("Votre commentaire à bien été mis à jour");
            this.props.onUpdate()
        });
    };

    onSubmitVehicle = (id) => {
        this.props.submitCommandVehicle(this.props.data.dbid, id)
            .then(r => this.setState({ vehicle: r.payload.data }, this.props.onUpdate))
    }
}

TrelloCardEditModal.defaultProps = {
    onClose: () => {
    }
};

export default connect(null, {submitCommandComment, submitCommandVehicle, fetchVehicles, submitCommandMaterialComment, commandAddMaterial, commandDeleteMaterial, commandIndexMaterial, fetchAll})(TrelloCardEditModal)
