import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_USER_INDEX = 'ENTITY_USER_INDEX'
const ENTITY_USER_INDEX_SUCCESS = 'ENTITY_USER_INDEX_SUCCESS'
const ENTITY_USER_INDEX_FAIL = 'ENTITY_USER_INDEX_FAIL'
const ENTITY_USER_RETRIEVE_HISTORIQUE = "ENTITY_USER_RETRIEVE_HISTORIQUE"

export function fetchAll()
{
    return request(ENTITY_USER_INDEX, 'get', '/users')
}

export function searchAll(query)
{
    return request(ENTITY_USER_INDEX, 'post', '/users/search', { query })
}

const ENTITY_USER_RETRIEVE = 'ENTITY_USER_RETRIEVE'

export function fetchUser(id)
{
    return request(ENTITY_USER_RETRIEVE, 'get', `/users/${id}`)
}

export function fetchUserHistorique(id)
{
    return request(ENTITY_USER_RETRIEVE_HISTORIQUE, 'get', `/users-historique/${id}`);
}

const ENTITY_USER_CREATE = 'ENTITY_USER_CREATE'

export function createUser(data)
{
    return request(ENTITY_USER_CREATE, 'post', '/users', data)
}

const ENTITY_USER_UPDATE = 'ENTITY_USER_UPDATE'

export function updateUser(id, data)
{
    return request(ENTITY_USER_UPDATE, 'patch', `/users/${id}`, data)
}


const ENTITY_USER_DELETE = 'ENTITY_USER_DELETE'

export function deleteUser(id)
{
    return request(ENTITY_USER_DELETE, 'delete', `/users/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityUserReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_USER_INDEX:
            return {...state, loading: true}
        case ENTITY_USER_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_USER_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
