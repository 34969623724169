import React, {Component} from 'react';
import '../../styles/generalpurpose/pseudoclasscolors.scss'
import '../../styles/generalpurpose/hoverdisplayproperties.scss'
import VehicleSelectorModal from "./VehicleSelectorModal";
import {connect} from "react-redux";

// Props with redux do not work here for some reason

class TrelloVehicleHeader extends Component
{
    state = {
        edition: false,
        currentVehicleName: this.props.vehicle.name
    };

    onEditClicked = () => {
        this.setState({edition: true});
    };

    onVehicleChangeClose = (change, what) => {
        if (change)
            this.setState({currentVehicleName: what.name, edition: false});
        else
            this.setState({edition: false});
    };

    // ====== RENDERS

    render() {
        return <div>
            {this.state.edition ? <VehicleSelectorModal onClose={this.onVehicleChangeClose} reduxes={this.props.reduxes} deliveryman={this.props.deliveryman} date={this.props.date}/> : ''}
            <div>
                <span className="font-weight-bolder">{this.props.title}</span>
                <span className="hover-cursor-pointer hover-underline text-muted float-right" onClick={this.onEditClicked}>{this.state.currentVehicleName}</span>
            </div>
        </div>
    }
}

TrelloVehicleHeader.defaultProps = {
    vehicle: {id: null, name: 'Aucun'}
};

export default connect(null, {})(TrelloVehicleHeader)
