import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_GEOLOCATIONAL_INDEX = 'ENTITY_GEOLOCATIONAL_INDEX'
const ENTITY_GEOLOCATIONAL_INDEX_SUCCESS = 'ENTITY_GEOLOCATIONAL_INDEX_SUCCESS'
const ENTITY_GEOLOCATIONAL_INDEX_FAIL = 'ENTITY_GEOLOCATIONAL_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_GEOLOCATIONAL_INDEX, 'get', '/geolocationals')
}

export function searchAll(query)
{
    return request(ENTITY_GEOLOCATIONAL_INDEX, 'post', '/geolocationals/search', { query })
}

const ENTITY_GEOLOCATIONAL_RETRIEVE = 'ENTITY_GEOLOCATIONAL_RETRIEVE'

export function fetchGeolocational(id)
{
    return request(ENTITY_GEOLOCATIONAL_RETRIEVE, 'get', `/geolocationals/${id}`)
}

const ENTITY_GEOLOCATIONAL_CREATE = 'ENTITY_GEOLOCATIONAL_CREATE'

export function createGeolocational(data)
{
    return request(ENTITY_GEOLOCATIONAL_CREATE, 'post', '/geolocationals', data)
}

const ENTITY_GEOLOCATIONAL_UPDATE = 'ENTITY_GEOLOCATIONAL_UPDATE'

export function updateGeolocational(id, data)
{
    return request(ENTITY_GEOLOCATIONAL_UPDATE, 'patch', `/geolocationals/${id}`, data)
}


const ENTITY_GEOLOCATIONAL_DELETE = 'ENTITY_GEOLOCATIONAL_DELETE'

export function deleteGeolocational(id)
{
    return request(ENTITY_GEOLOCATIONAL_DELETE, 'delete', `/geolocationals/${id}`)
}

const ENTITY_GEOLOCATIONAL_GETLATEST = 'ENTITY_GEOLOCATIONAL_GETLATEST';

export function getlatest()
{
    return request(ENTITY_GEOLOCATIONAL_GETLATEST, 'get', `/geolocationals/getlatest`);
}

const ENTITY_GEOLOCATIONAL_GETLATEST_ONE = 'ENTITY_GEOLOCATIONAL_GETLATEST_ONE';

export function getOneLatest(id)
{
    return request(ENTITY_GEOLOCATIONAL_GETLATEST_ONE, 'get', `/geolocationals/getonelatest/${id}`);
}

const ENTITY_GEOLOCATIONAL_GET_USER_DAY = 'ENTITY_GEOLOCATIONAL_GET_USER_DAY';

export function getForUserForDay(user, day)
{
    // POST parce que date..
    return request(ENTITY_GEOLOCATIONAL_GET_USER_DAY, 'POST', `/geolocationals/getforuserforday`, {user, day});
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityGeolocationalReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_GEOLOCATIONAL_INDEX:
            return {...state, loading: true}
        case ENTITY_GEOLOCATIONAL_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_GEOLOCATIONAL_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
