import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import UserCreateModal from "./UserCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/User'

class UserIndexPage extends Component
{
    state = {
        search: '',
        showUserCreateModal: false,
        users: this.props.user_entity.index
    };

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    };

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    };

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>User</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <h1>Liste des utilisateurs</h1>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.user_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button className="ml-3" onClick={() => this.setState({ showUserCreateModal: true })}><i className='fa fa-user-plus mr-2'/> Ajouter un utilisateur</button>
                    </div>
                </div>
                {this.renderEntities()}
                {this.state.showUserCreateModal ? (
                    <UserCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showUserCreateModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }

    componentWillReceiveProps(props)
    {
        if (props.user_entity && props.user_entity.index)
        {
            this.setState({users: props.user_entity.index});
        }
    }

    sortby = (key) => {
        if (!this.sorts)
            this.sorts = {};

        if (!this.sorts[key])
            this.sorts[key] = 1;

        let sortme = this.state.users;
        const reverse = this.sorts[key];

        sortme.sort((a, b) => {
            if (a[key] < b[key])
                return -1 * reverse;
            if (a[key] > b[key])
                return 1 * reverse;

            return 0;
        });

        this.sorts[key] *= -1;

        this.setState({users: sortme});
    };

    caretClassNames = (key) => {
        let direction = 'fa-caret-down';
        if (this.sorts && this.sorts[key] && this.sorts[key] === -1)
            direction = "fa-caret-up";

        return "fa d-none show-on-any-parent-hover " + direction;
    };

    renderEntities()
    {
        if (this.props.user_entity.loading && !this.props.user_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.user_entity.index.length) {
            return <Empty/>
        }


        return (
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('id')}>id <i style={{marginRight: '-30px'}} className={this.caretClassNames('id')} /></th>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('email')}>Email <i style={{marginRight: '-30px'}} className={this.caretClassNames('email')} /></th>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('phone')}>Téléphone <i style={{marginRight: '-30px'}} className={this.caretClassNames('phone')} /></th>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('firstname')}>Prénom <i style={{marginRight: '-30px'}} className={this.caretClassNames('firstname')} /></th>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('lastname')}>Nom <i style={{marginRight: '-30px'}} className={this.caretClassNames('lastname')} /></th>
                        <th className="hover-parent hover-cursor-pointer" onClick={() => this.sortby('role')}>Rôle <i style={{marginRight: '-30px'}} className={this.caretClassNames('role')} /></th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                {this.state.users.map((entity, key) => (
                    <tr key={key}>
                        <td>
                            {entity.id}
                        </td>
                        <td>
                            {entity.email}
                        </td>
                        <td>
                            {entity.phone}
                        </td>
                        <td>
                            {entity.firstname}
                        </td>
                        <td>
                            {entity.lastname}
                        </td>
                        <td>
                            {entity.role}
                        </td>
                        <td>
                            <Link to={"/entities/user/" + entity.id}><button>Détails <i className='fa fa-arrow-right ml-1'/></button></Link>
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
        )
    }
}

export default connect(({ user_entity }) => ({ user_entity }), { fetchAll, searchAll })(UserIndexPage)
