import React, {Component} from 'react';
import '../../styles/generalpurpose/pseudoclasscolors.scss'
import '../../styles/generalpurpose/hoverdisplayproperties.scss'
import {connect} from "react-redux";
import Loader from "../Loader";

class RBCToolbar extends Component {
    state = {};

    render() {
        return <div className='my-4'>
            <div className='d-flex justify-content-center'>
                <div className='hover-cursor-pointer' onClick={this.navigate.bind(null, 'PREV')}><i className='fa fa-arrow-circle-left' style={{fontSize: 28, color: '#9e721d'}}/></div>
                <div className='text-center mx-5'>{this.renderLabel()}</div>
                <div className='hover-cursor-pointer' onClick={this.navigate.bind(null, 'NEXT')}><i className='fa fa-arrow-circle-right' style={{fontSize: 28, color: '#9e721d'}}/></div>
            </div>
{/*            <div className='text-center'>
                <button onClick={this.navigate.bind(null, 'TODAY')}>Cette semaine</button>
            </div>*/}
        </div>
    }

    renderLabel = () => {
        if (this.props.loading)
            return <div style={{width: 92}} className='text-center'><Loader display={true}/></div>;

        return this.props.label;
    };

    navigate = action => {
        this.props.onNavigate(action)
    }
}

RBCToolbar.defaultProps = {};

export default connect(null, {})(RBCToolbar)
