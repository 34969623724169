import React, {Component} from 'react';
import {connect} from 'react-redux';
import {APP_NAME} from "../config/parameters";
import {Helmet} from "react-helmet";


class HomePage extends Component
{
    render()
    {
        return (
            <div>
                <Helmet>
                    <title>{APP_NAME}</title>
                </Helmet>
                <div className="container">
                    Bienvenue sur Lapierre Traiteur
                </div>
            </div>
        )
    }
}

export default connect(({ user }) => ({ user }), null)(HomePage)
