import React, {Component} from 'react';
import Modal from '../Modal';
import {connect} from "react-redux";
import Loader from "../Loader";

class VehicleSelectorModal extends Component {
    state = {
        loading: true,
        vehicles: [],
        vehicle: 0
    };

    componentDidMount() {
        this.props.reduxes.fetchAllVehicles().then((r) => {
            this.setState({vehicles: r.payload.data, loading: false})
        })
    }

    sendNewVehicle = () => {
        this.setState({loading: true});

        this.props.reduxes.setUVD(this.props.deliveryman, this.state.vehicle, this.props.date).then((r) => {

            // /!\ Ne PAS remplacer par === !!!!
            let vehicle = this.state.vehicles.find(v => v.id == this.state.vehicle);

            if (!vehicle)
                vehicle = {id: null, name: 'Aucun'};

            this.props.onClose(true, vehicle);
        })
    };

    renderVehicles = () => {
        if (this.state.loading)
            return '';

        return (<div>
            <select
                className="form-control"
            value={this.state.vehicle}
            onChange={(e) => this.setState({ vehicle: e.target.value })}>
                <option value={0} id="vehicle-none">Aucun</option>
                {this.state.vehicles.map((v) => {return <option value={v.id} key={v.id}>{v.name}</option>})}
            </select>
            <input type="submit" className="btn btn-primary mt-2" onClick={this.sendNewVehicle}/>
        </div>);
    };


    render() {
        return (
            <Modal title="Sélection de véhicule" onClose={() => this.props.onClose(null)}>
                <Loader display={this.state.loading}/>
                {this.renderVehicles()}
            </Modal>
        );
    }


}

export default connect(null, {})(VehicleSelectorModal)
