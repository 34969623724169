import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_UVD_OP = 'ENTITY_UVD_OP'
const ENTITY_UVD_OP_SUCCESS = 'ENTITY_UVD_OP_SUCCESS'
const ENTITY_UVD_OP_FAIL = 'ENTITY_UVD_OP_FAIL'

export function setUVD(user, vehicle, day)
{
    return request(ENTITY_UVD_OP, 'POST', '/uvds/set', {user, vehicle, day})
}


// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityUVDReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_UVD_OP:
            return {...state, loading: true}
        case ENTITY_UVD_OP_FAIL:
            return {...state, loading: false}
        case ENTITY_UVD_OP_SUCCESS:
            return {...state, loading: false, OP: action.payload.data, lastUpdate: moment()}
    }
    return state
}
