import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {ROLE_ANONYMOUS} from "./config/security";

class RouteHandler extends Component
{
    shouldRedirect()
    {
        if (this.props.security) {
            if (this.props.security.roles) {
                if (!this.props.user.profile) {
                    if (this.props.security.roles.indexOf(ROLE_ANONYMOUS) === -1) {
                        return '/auth/login'
                    }
                }
                else {
                    if (this.props.security.roles.filter((r) => -1 !== this.props.user.profile.roles.indexOf(r)).length === 0)
                        return '/';
                }
            }
        }
        return false
    }

    render()
    {
        const layout = this.props.layout
        const props = this.props.routeProps ? this.props.routeProps : {}

        const route = this.shouldRedirect()
        if (route) return <Redirect to={route} />

        let className = ['PageHandler']

        if (props.className) {
            className.push(props.className)
        }

        className = className.join(' ')

        return (
            <main className={className}>
                {this.renderLayout(layout, props)}
            </main>
        )
    }

    renderLayout(layout, props)
    {
        if (layout) {
            return React.createElement(layout, props, this.props.children);
        }

        return this.props.children
    }
}

export default connect(({ user }) => ({ user }), null)(RouteHandler)
