import React, {Component} from 'react';
import Modal from "../../../components/Modal";
import BMap from "../../../components/BMap";
import moment from "moment-timezone";
import Accordion from "../../../components/Accordion";
import {getForUserForDay} from '../../../data/entities/Geolocational';
import connect from "react-redux/es/connect/connect";

class UserPersonnalMapModal extends Component {
    state = {
        dateFilter: new Date(),
        markers: this.props.markers,
        position: [48.923798, 2.345888]
    };

    // On change de jour. La liste des commandes sera filtrée mais nous n'avons pas les marqueurs
    // Pour la map. Il faut les récupérer.
    changeday = (amount) => {
        this.setState({dateFilter: moment(this.state.dateFilter).add(amount, 'day')}, () => {
            this.props.getForUserForDay(this.props.deliveryman.id, this.state.dateFilter).then((r) => {
                this.setState({markers: r.payload.data});
            })
        });
    };

    findLatestMarkerForCommand = (c) => {
        return this.state.markers.filter(x => x.command && x.command.dbid === c.id).reduce((l, r) => l.date < r.date ? r : l);
    };

    moveToCommand = (c) => {
        const marker = this.findLatestMarkerForCommand(c);

        if (!marker) {
            alert('Aucune position connue pour cette livraison');
            return;
        }

        const pos = [marker.latitude, marker.longitude];
        console.log('Navigating to ', pos);

        // Dirty quick hack to fix when click multiple times
        this.setState({position: [pos[0] + 0.0000001, pos[1]]}, () => this.setState({position: pos}));
    };

    // command2accordion
    c2a = (c) => {
        let data = [];
        data.push([<label>Date de livraison :</label>, <span>{moment(c.delivery_position).tz("Europe/Paris").format('LLL')}</span>]);
        data.push([<label>Date livrée :</label>, <span>{c.delivered_date ? moment(c.delivered_date).tz("Europe/Paris").format('LLL') : 'Non livrée'}</span>]);
        data.push([<label>Adresse de livraison :</label>, c.address]);
        data.push([<label>Lieu :</label>, <div><span className='link-like' onClick={() => this.moveToCommand(c)}><i className='fa fa-map-marker mr-1'/> Voir</span></div>]);

        return <Accordion key={'modal-' + c.id} id={'modal-acc-' + c.id} data={data} title={c.name}/>
    };

    renderCommands = (commands) => {
        if (!commands.length)
            return <em>Aucune commande pour ce livreur</em>;

        const filter = <div className='d-flex justify-content-around'>
            <div onClick={() => this.changeday(-1)}><i className='fa fa-arrow-left hover-cursor-pointer'/></div>
            <div>{moment(this.state.dateFilter).tz("Europe/Paris").format('LL')}</div>
            <div onClick={() => this.changeday(+1)}><i className='fa fa-arrow-right hover-cursor-pointer'/></div>
        </div>;

        commands = this.props.filterFunc(this.state.dateFilter, this.props.commands);
        const cmds = commands.map(c => <div key={'userpersomap-' + c.id}>
            {this.c2a(c)}
        </div>);

        return <div>
            {filter}
            <hr/>
            {cmds}
        </div>
    };

    render() {
        return (
            <Modal onClose={() => this.props.onClose()} title={"Carte du livreur " + this.props.deliveryman.name} widthOverride='70%'>
                <div style={{height: 500}} className={'container'}>
                    <div className='row'>
                        <div className='col-9'>
                            <BMap
                                position={this.state.position}
                                markers={this.state.markers}
                                customStyle={{height: 500}}
                                renderPath={true}
                                onUpdateRequest={this.props.onUpdateRequest}
                            />
                        </div>
                        <div className='col-3'>
                            <h4>Commandes de la journée</h4>
                            {this.renderCommands(this.props.commands)}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

UserPersonnalMapModal.defaultProps = {
    filterFunc: (day, cmds) => cmds,
    markers: []
};

export default connect(({ user_entity }) => ({ user_entity }), { getForUserForDay })(UserPersonnalMapModal)
