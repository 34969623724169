import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_NOTIFICATION_INDEX = 'ENTITY_NOTIFICATION_INDEX'
const ENTITY_NOTIFICATION_INDEX_SUCCESS = 'ENTITY_NOTIFICATION_INDEX_SUCCESS'
const ENTITY_NOTIFICATION_INDEX_FAIL = 'ENTITY_NOTIFICATION_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_NOTIFICATION_INDEX, 'get', '/notifications')
}

export function searchAll(query)
{
    return request(ENTITY_NOTIFICATION_INDEX, 'post', '/notifications/search', { query })
}

const ENTITY_NOTIFICATION_RETRIEVE = 'ENTITY_NOTIFICATION_RETRIEVE'

export function fetchNotification(id)
{
    return request(ENTITY_NOTIFICATION_RETRIEVE, 'get', `/notifications/${id}`)
}

const ENTITY_NOTIFICATION_PAGINATION = 'ENTITY_NOTIFICATION_PAGINATION'

export function fetchByPage(page)
{
    return request(ENTITY_NOTIFICATION_PAGINATION, 'get', `/notifications-pagination/${page}`);
}

const ENTITY_NOTIFICATION_CREATE = 'ENTITY_NOTIFICATION_CREATE'

export function createNotification(data)
{
    return request(ENTITY_NOTIFICATION_CREATE, 'post', '/notifications', data)
}

const ENTITY_NOTIFICATION_UPDATE = 'ENTITY_NOTIFICATION_UPDATE'

export function updateNotification(id, data)
{
    return request(ENTITY_NOTIFICATION_UPDATE, 'patch', `/notifications/${id}`, data)
}


const ENTITY_NOTIFICATION_DELETE = 'ENTITY_NOTIFICATION_DELETE'

export function deleteNotification(id)
{
    return request(ENTITY_NOTIFICATION_DELETE, 'delete', `/notifications/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityNotificationReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_NOTIFICATION_INDEX:
            return {...state, loading: true}
        case ENTITY_NOTIFICATION_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_NOTIFICATION_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
