import {request} from '../helpers'
import moment from 'moment';
import {toast} from 'react-toastify';
import {OAUTH_IDENTIFIER, OAUTH_SECRET} from "../config/parameters";

let status = -1

//

const OAUTH_GUEST_READY = 'OAUTH_GUEST_READY'
const OAUTH_USER_READY = 'OAUTH_USER_READY'

export function authTick()
{
    return (dispatch, getState) => {
        let state = {...getState()}
        let data = localStorage

        // Boot needed
        if (status === -1) {
            let refresh_token = data.getItem('oauth_refresh_token')
            let access_token = data.getItem('oauth_access_token')
            let expires_at = data.getItem('oauth_expires_at')

            if (expires_at && access_token && expires_at)
            {
                if (moment(parseInt(expires_at)).isAfter(moment().add(2, 'minutes'))) {
                    dispatch({
                        type: OAUTH_USER_READY,
                        refresh_token,
                        access_token,
                        expires_at: moment(parseInt(expires_at))
                    })
                }
                else {
                    dispatch(refreshToken(refresh_token))
                }
            }
            else {
                dispatch({
                    type: OAUTH_GUEST_READY
                })
            }
        }
        // Booting
        else if (status === 0) {
            // Ignored
        }
        // Booted
        else {
            const local_expires_at = localStorage.getItem('oauth_expires_at')
            if (state.oauth.expires_at)
            {
                if (!local_expires_at) {
                    dispatch(logout())
                }
                else if (moment(parseInt(local_expires_at)).isAfter(state.oauth.expires_at)) {
                    dispatch({
                        type: OAUTH_USER_READY,
                        refresh_token: data.getItem('oauth_refresh_token'),
                        access_token: data.getItem('oauth_access_token'),
                        expires_at: moment(data.getItem('oauth_expires_at'))
                    })
                }
                else if (moment(parseInt(local_expires_at)).isBefore(moment().add(2, 'minutes'))) {
                    status = 0
                    dispatch(refreshToken(data.getItem('oauth_refresh_token')))
                }
            }
            else {
                if (local_expires_at) {
                    dispatch({
                        type: OAUTH_USER_READY,
                        refresh_token: data.getItem('oauth_refresh_token'),
                        access_token: data.getItem('oauth_access_token'),
                        expires_at: moment(data.getItem('oauth_expires_at'))
                    })
                }
            }
        }

        setTimeout(() => {
            dispatch(authTick())
        }, 1000)
    }
}

export const OAUTH_LOGOUT = 'OAUTH_LOGOUT'

export function logout()
{
    return {
        type: OAUTH_LOGOUT
    }
}

const OAUTH_LOGIN = 'OAUTH_LOGIN'
const OAUTH_LOGIN_SUCCESS = 'OAUTH_LOGIN_SUCCESS'
const OAUTH_LOGIN_FAIL = 'OAUTH_LOGIN_FAIL'

export function login(username, password)
{
    return request(OAUTH_LOGIN, 'post', '/token', {
        client_id: OAUTH_IDENTIFIER,
        client_secret: OAUTH_SECRET,
        grant_type: 'password',
        username,
        password
    }, {
        date: moment()
    })
}

const AUTH_REGISTER = 'AUTH_REGISTER'

export function register(email, password)
{
    return request(AUTH_REGISTER, 'post', '/auth/register', { email, password })
}

const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD'

export function forgotPassword(email)
{
    return request(AUTH_FORGOT_PASSWORD, 'post', '/auth/forgot-password', { email })
}

const OAUTH_REFRESH_TOKEN = 'OAUTH_REFRESH_TOKEN'
const OAUTH_REFRESH_TOKEN_SUCCESS = 'OAUTH_REFRESH_TOKEN_SUCCESS'
const OAUTH_REFRESH_TOKEN_FAIL = 'OAUTH_REFRESH_TOKEN_FAIL'

export function refreshToken(refresh_token)
{
    return (dispatch, getState) => {
        dispatch(request(OAUTH_REFRESH_TOKEN, 'post', '/token', {
            client_id: OAUTH_IDENTIFIER,
            client_secret: OAUTH_SECRET,
            grant_type: 'refresh_token',
            refresh_token: refresh_token ? refresh_token : getState().oauth.refresh_token
        }, {
            date: moment()
        }))
    }
}

// Store

const defaultState = {
    ready: false,
    refresh_token: null,
    access_token: null,
    expires_at: null,
    last_update: null
}

// Reducer

export default function OAuthReducer(state = defaultState, action)
{
    console.log(`[OAuth] ${action.type}`, action)
    switch(action.type)
    {
        case OAUTH_LOGOUT:
            status = 1
            localStorage.removeItem('oauth_access_token')
            localStorage.removeItem('oauth_refresh_token')
            localStorage.removeItem('oauth_expires_at')
            return {
                ...defaultState,
                ready: true
            }

        case OAUTH_GUEST_READY:
            status = 1
            localStorage.removeItem('oauth_access_token')
            localStorage.removeItem('oauth_refresh_token')
            localStorage.removeItem('oauth_expires_at')
            return {
                ...state,
                ready: true
            }

        case OAUTH_USER_READY:
            status = 1
            return {
                ...state,
                access_token: action.access_token,
                refresh_token: action.refresh_token,
                expires_at: action.expires_at,
                last_update: moment(),
                ready: true
            }

        case OAUTH_LOGIN:
        case OAUTH_REFRESH_TOKEN:
            status = 0
            return state

        case OAUTH_LOGIN_SUCCESS:
            toast.success('Bienvenue sur Lapierre Traiteur!')
        case OAUTH_REFRESH_TOKEN_SUCCESS:
            status = 1
            localStorage.setItem('oauth_access_token', action.payload.data.access_token)
            localStorage.setItem('oauth_refresh_token', action.payload.data.refresh_token)
            localStorage.setItem('oauth_expires_at', Math.round(calculateExpirationDate(action.meta.previousAction.date, action.payload.data.expires_in).valueOf()))
            return {
                ...state,
                access_token: action.payload.data.access_token,
                refresh_token: action.payload.data.refresh_token,
                expires_at: calculateExpirationDate(action.meta.previousAction.date, action.payload.data.expires_in),
                last_update: moment(),
                ready: true
            }

        case OAUTH_LOGIN_FAIL:
        case OAUTH_REFRESH_TOKEN_FAIL:
            status = 1
            return {
                ...defaultState,
                ready: true
            }
        default: break
    }
    return state
}

// Helpers

function calculateExpirationDate(date, expiration) {
    return date.add(expiration, 'seconds')
}
