import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {FormattedMessage} from "react-intl";
import ContentLoader from "../../../components/ContentLoader";
import { fetchUserHistorique } from '../../../data/entities/User'
import { getOneLatest } from "../../../data/entities/Geolocational";
import UserUpdateModal from "./UserUpdateModal";
import UserDeleteModal from "./UserDeleteModal";
import UserPersonnalMapModal from "./UserPersonnalMapModal";
import moment from 'moment-timezone';
import Accordion from "../../../components/Accordion";
import {ROLE_ADMIN, ROLE_CLIENT} from "../../../config/security";

class UserViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: {},
        dateFilter: null,
        loading: true,
        showUserUpdateModal: false,
        showUserDeleteModal: false,
        showUserPersonnalMap: false,
        latest: []
    };

    componentDidMount() {
        this.props.fetchUserHistorique(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data });
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)

                this.props.history.push('/entities/user')
            });

        this.fetchMap()
    }

    fetchMap() {
        this.props.getOneLatest(this.state.id)
            .then((res) => {
                this.setState({latest: res.payload.data});
            })
            .catch(err => console.error(err));
    }

    render() {
        return (
            <div className="container">
                <h1>{this.state.data.firstname} {this.state.data.lastname}</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderUserInformations() {

        const self_user = this.props.user && this.props.user.profile && this.props.user.profile.id === this.state.data.id;

        return (
            <div className={'col-xl-4'}>
                <div className={'admin-card'}>
                    <h4>
                        Données de l'entité
                    </h4>
                    <hr/>
                    <div className={'information'}>
                        <label>Identifiant : </label>
                        <span>{this.state.data.device_id}</span>
                    </div>

                    <div className={'information'}>
                        <label>Téléphone : </label>
                        <span>{this.state.data.phone}</span>
                    </div>

                    <div className={'information'}>
                        <label>Email : </label>
                        <span>{this.state.data.email}</span>
                    </div>

                    { this.state.data.role === "ROLE_CLIENT" ? <div>

                    <div className={'information'}>
                        <label>Adresse de livraison : </label>
                        <span>{this.state.data.delivery_address || "Non renseignée"}</span>
                    </div>

                    <div className={'information'}>
                        <label>Adresse de facturation : </label>
                        <span>{this.state.data.billing_address || "Non renseignée"}</span>
                    </div>

                    </div> : '' }

                    <div className={'information'}>
                        <label>Role : </label>
                        <span>{this.state.data.role}</span>
                    </div>

                    <div className={'information'}>
                        <label>Prénom : </label>
                        <span>{this.state.data.firstname}</span>
                    </div>

                    <div className={'information'}>
                        <label>Nom : </label>
                        <span>{this.state.data.lastname}</span>
                    </div>

                    <hr/>

                    <div className={'actions'}>
                        <button
                            onClick={() => this.setState({ showUserUpdateModal: true })}
                            className="btn btn-primary"><i className='fa fa-user-edit' /> Modifier</button>
                        {!self_user ?
                            <button
                                onClick={() => this.setState({ showUserDeleteModal: true })}
                                className="btn btn-danger ml-2"><i className='fa fa-times mr-1' /> Supprimer</button>
                            : null
                        }
                    </div>
                    {this.state.showUserUpdateModal ? (
                        <UserUpdateModal
                            data={this.state.data}
                            onClose={(data) => {
                                if (data)
                                    this.props.history.go('/entities/user/' + data.id);
                                this.setState({ showUserUpdateModal: false })
                            }}
                        />
                    ) : null}
                    {this.state.showUserDeleteModal ? (
                        <UserDeleteModal
                            data={this.state.data}
                            onClose={(deleted) => {
                                if (deleted) {
                                    this.props.history.push('/entities/user')
                                }
                                else this.setState({ showUserDeleteModal: false })
                            }}
                        />
                    ) : null}
                </div>
            </div>
        )
    }

    commandsForDay = (day, commands) => {
        const theDayAtMidnight = moment(day).set({hour: 0, minute: 0, second: 0}).toDate();
        const theDayAtMidnightButTheOtherOne = moment(day).set({hour: 23, minute: 59, second: 59}).toDate();

        return commands.filter(x => moment(x.delivery_position).toDate() > theDayAtMidnight && moment(x.delivery_position).toDate() < theDayAtMidnightButTheOtherOne);
    };

    renderHistorique() {
        if (this.state.data.role === ROLE_ADMIN)
            return '';

            const wording = this.state.data.role === 'ROLE_CLIENT' ? 'passées' : 'livrées';

        let data = null;
        if (this.state.data.commands.length === 0)
        {
            data = <em>Aucune commande enregistrée</em>;
        }
        else if (this.state.dateFilter)
        {
            data = this.commandsForDay(this.state.dateFilter, this.state.data.commands);
            data = data.map(c => this.command2Accordion(c));
        }
        else
        {
            data = this.state.data.commands.map(c => this.command2Accordion(c));
        }

        return (
            <div className={'col-xl-4'}>
                <div className={'admin-card'}>
                    <h4>
                        Historique des commandes {wording}
                    </h4>
                    <h6>{this.state.data.commands.length} commandes au total</h6>
                    <hr/>
                    <label>Filter par date</label>
                    <input className='form-control' type='date' onChange={(e) => this.setState({dateFilter: e.target.value})}/>
                    <hr/>
                    { data }
                </div>
            </div>
        )
    }

    command2Accordion = (c) => {
        let data = [];
        let pic = <span>La livraison n'a pas encore reçu d'image de confirmation</span>;
        if (c.success_delivery_picture)
            pic = <img src={c.success_delivery_picture} style={{ height: 100 }} alt="delivery"/>;

        data.push([<label>Date de livraison :</label>, <span>{moment(c.delivery_position).tz("Europe/Paris").format('LLL')}</span>]);
        data.push([<label>Image de confirmation :</label>, pic]);
        data.push([<label>Adresse de livraison :</label>, c.address]);

        return <Accordion key={c.id} id={'acc-' + c.id} data={data} title={c.name}/>
    };

    renderPersonnalMap() {
        if (this.state.data.role === ROLE_CLIENT || this.state.data.role === ROLE_ADMIN) {
            return '';
        }

        return (
            <div className={'col-xl-4'}>
                <div className={'admin-card'}>
                    <h4>
                        Carte du livreur
                    </h4>
                    <hr/>
                    <button onClick={() => this.setState({ showUserPersonnalMap: true })}><i className='fa fa-map mr-1'/> Voir la carte du livreur</button>
                    {
                        this.state.showUserPersonnalMap
                            ?   (
                                  <UserPersonnalMapModal
                                      onClose={() => this.setState({ showUserPersonnalMap: false})}
                                      deliveryman={{id: this.state.data.id, name: this.state.data.firstname + " " + this.state.data.lastname}}
                                      markers={this.state.latest}
                                      commands={this.state.data.commands}
                                      filterFunc={this.commandsForDay}
                                      onUpdateRequest={() => this.fetchMap()}
                                  />
                                )
                            : null
                    }
                </div>
            </div>
        )
    }

    renderEntity() {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div className={'row'}>
                {this.renderUserInformations()}
                {this.renderHistorique()}
                {false && this.renderPersonnalMap()}
            </div>
        )
    }
}

export default connect(({ user_entity, user }) => ({ user_entity, user }), { fetchUserHistorique, getOneLatest })(UserViewPage)
