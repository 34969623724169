import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from "../Loader";
import ReactTrello from 'react-trello';
import {getCommandsForDay, changeDeliveryman, updatePosition} from "../../data/entities/Command";
import {fetchAll} from "../../data/entities/Vehicle";
import {setUVD} from "../../data/entities/UserVehicleDay";
import TrelloCardEditModal from "./TrelloCardEditModal";
import moment from "moment-timezone";
import '../../styles/generalpurpose/hoverdisplayproperties.scss';
import TrelloVehicleHeader from "./TrelloVehicleHeader";
import CommandDeleteModal from "../../pages/entities/Command/CommandDeleteModal";
import {Link} from "react-router-dom";
import {DatePicker} from "react-forms";

class Trello extends Component {
    // ===== Constructors / INIT

    state = {
        lanes: [],
        loading: true,
        showEditModal: false,
        taskData: {},
        updateKey: 0,
        editable: false,
        date: new Date(),
        showCommandDeleteModal: false
    };


    // ====== CLIENTSIDE INTERACTIONS

    toDbId = (id) => {
        if (typeof id === "string")
            return parseInt(id.split('-')[1]);

        return id;
    };

    getCardById = (cardId) => {
        for (const l of this.state.lanes) {
            for (const c of l.cards) {
                if (c.id === cardId || c.db_id === cardId)
                    return c;
            }
        }
    };

    getUserDisplayById = (userId) => {
        if (!userId)
            return null;

        userId = parseInt(userId);

        for (const u of this.props.users) {
            if (u.id === userId)
                return u.firstname;
        }

        return '????';
    };

    // Card HAS to be formatted for react-trello
    setCardById = (card) => {
        let stop = true;
        this.state.lanes.every((l) => {
            l.cards.every((c, i, rc) => {
                if (c.id === card.id) {
                    rc[i] = card;
                    stop = false;
                }
                return stop;
            });
            return stop;
        });

        return !stop;
    };

    componentDidMount() {
        this.loadBoard(this.state.date);
    }

    // ========= ONLINE INTERACTIONS

    loadBoard = (date) => {
        // L'api prend le jour de date, et renvoie toutes les commandes de cette date

        this.setState({loading: true});
        this.props.getCommandsForDay(date).then((r) => {
            this.setState({lanes: r.payload.data.lanes, loading: false, updateKey: this.state.updateKey + 1}, () => {
                this.setState({loading: false});
            });
        });
    };

    handleLaneDragEnd = (oldpos, newpos, lane) => {

    };

    handleDragEnd = (cardKey, oldLaneKey, newLaneKey, newPositionInLane, card) => {
        if(oldLaneKey === newLaneKey) {
            if (newLaneKey == "lane-unassigned")
                return;

            this.props.updatePosition(card.dbid, newPositionInLane)
            return;
        }

        let newDeliveryman = this.state.lanes.find(l => l.id === newLaneKey);

        if (!newDeliveryman)
            console.error("No delivery man found");

        newDeliveryman = newDeliveryman.deliveryman;

        this.props.changeDeliveryman(card.type, card.dbid, newDeliveryman, newPositionInLane).then((r) => {
            this.loadBoard(this.state.date);
        })
    };

    onCardAdd = (card, laneId) => {

    };

    changeDay = (howmany) => {
        this.setState({date: moment(this.state.date).add(howmany, 'days').toDate()}, () => {
            this.loadBoard(this.state.date);
        });
    };

    // ====== MODALS

    onCardClick = (cardId, thisPackageIsBuggedAF, laneId) => {
        const card = this.getCardById(cardId);
        this.setState({taskData: card, showEditModal: true});
    };

    onEditClose = (data) => {

        let stop = true;
        this.state.lanes.every((l) => {
            l.cards.every((c, i, rc) => {
                if (c.dbid === data.id) {
                    rc[i].materials = data.materials;
                    stop = false;
                }
                return stop;
            });
            return stop;
        });

        if (!stop)
        {
            this.setState({showEditModal: false, taskData: {}, lanes: this.state.lanes});
        }

    };

    onDelete = (deleted) => {
        if (deleted)
            this.setState({ showEditModal: false }, () => this.loadBoard(this.state.date));
    }

    // ====== RENDERS

    render() {
        return <div>
            {this.state.showEditModal ?
                <TrelloCardEditModal
                    data={this.state.taskData}
                    onClose={this.onEditClose}
                    onUpdate={() => this.loadBoard(this.state.date)}
                    onDelete={(deleted) => this.onDelete(deleted)}/>
                : null}
            {this.state.loading ? this.renderLoading() : this.renderTrello() }
            {this.state.showCommandDeleteModal ?
                <CommandDeleteModal
                    data={this.state.entity}
                    onClose={(deleted) => {
                        if (deleted) {
                            this.setState({ showVehicleDeleteModal: false }, () => this._fetch())
                        }
                        else this.toggleDeleteModal()
                    }}
                /> : null}
        </div>
    }

    renderTrello = () => {
        return <div>
            <div className="text-center mb-3 mt-2">
                <i onClick={() => this.changeDay(-1)} className="hover-cursor-pointer fa fa-arrow-left"/>
                <input
                    className="mx-3"
                    value={moment(this.state.date).tz("Europe/Paris").locale('fr').format("YYYY-MM-DD")}
                    type="date"
                    onChange={e => {
                        const date = moment(e.target.value)
                        this.setState({
                            date: moment(this.state.date).set({
                                date: date.get('date'),
                                month: date.get('month'),
                                year: date.get('year')
                            }).toDate()
                        }, () => {
                            this.loadBoard(this.state.date);
                        })
                    }}
                />
                <i onClick={() => this.changeDay(1)} className="hover-cursor-pointer fa fa-arrow-right"/>
                <Link to={'/order/schedule/read-only'} className={'float-right'}>lecture seule</Link>
            </div>

            <ReactTrello
                draggable={true}
                key={this.state.updateKey}
                data={{lanes: this.state.lanes}}
                handleLaneDragEnd={this.handleLaneDragEnd}
                handleDragEnd={this.handleDragEnd}
                onCardAdd={this.onCardAdd}
                onCardClick={this.onCardClick}

                hideCardDeleteIcon={true}
                customLaneHeader={<TrelloVehicleHeader reduxes={{fetchAllVehicles: this.props.fetchAll, setUVD: this.props.setUVD}} date={this.state.date}/>}
                style={{background: 'none'}}
                laneDraggable={false}
            />
        </div>
    };

    renderLoading = () => {
        return <div className="text-center p-5">
            <Loader display={true}/>
        </div>
    };

}

Trello.defaultProps = {
    board: 0,
    users: []
};

export default connect(null, {getCommandsForDay, updatePosition, changeDeliveryman, fetchAll, setUVD})(Trello);
