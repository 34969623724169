import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_MATERIAL_INDEX = 'ENTITY_MATERIAL_INDEX'
const ENTITY_MATERIAL_INDEX_SUCCESS = 'ENTITY_MATERIAL_INDEX_SUCCESS'
const ENTITY_MATERIAL_INDEX_FAIL = 'ENTITY_MATERIAL_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_MATERIAL_INDEX, 'get', '/materials')
}

export function searchAll(query)
{
    return request(ENTITY_MATERIAL_INDEX, 'post', '/materials/search', { query })
}

const ENTITY_MATERIAL_RETRIEVE = 'ENTITY_MATERIAL_RETRIEVE'

export function fetchMaterial(id)
{
    return request(ENTITY_MATERIAL_RETRIEVE, 'get', `/materials/${id}`)
}

const ENTITY_MATERIAL_CREATE = 'ENTITY_MATERIAL_CREATE'

export function createMaterial(data)
{
    return request(ENTITY_MATERIAL_CREATE, 'post', '/materials', data)
}

const ENTITY_MATERIAL_UPDATE = 'ENTITY_MATERIAL_UPDATE'

export function updateMaterial(id, data)
{
    return request(ENTITY_MATERIAL_UPDATE, 'patch', `/materials/${id}`, data)
}


const ENTITY_MATERIAL_DELETE = 'ENTITY_MATERIAL_DELETE'

export function deleteMaterial(id)
{
    return request(ENTITY_MATERIAL_DELETE, 'delete', `/materials/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityMaterialReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_MATERIAL_INDEX:
            return {...state, loading: true}
        case ENTITY_MATERIAL_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_MATERIAL_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}