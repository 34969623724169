import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {getDeliveryManStatistiques} from '../data/User';

class StatistiquePage extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.renderStatistiques = this.renderStatistiques.bind(this);
    }

    componentDidMount() {
        this.props.getDeliveryManStatistiques();
    }

    renderStatistiques() {
        console.log(this.props.statistiques);
        return (
            <table>
                <thead className="table table-striped mt-3">
                    <tr>
                        <th>Nom du livreur</th>
                        <th>Nombre de livraisons</th>
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        )
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Statistiques</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <h1>Statistiques du jour</h1>
                    </div>
                </div>
                {this.renderStatistiques()}
            </div>
        );
    }

}

export default connect(({ statistiques }) => ({ statistiques}), { getDeliveryManStatistiques })(StatistiquePage);
