import React, {Component} from 'react';
import {Map, TileLayer, Marker, Popup} from "react-leaflet";
import Control from 'react-leaflet-control';
import {Link} from "react-router-dom";
import L from "leaflet";
import moment from "moment-timezone";
import 'leaflet/dist/leaflet.css';
import '../styles/customleaflet.scss';
import '../styles/map/mapbutton.scss';
import AntPath from 'react-leaflet-ant-path';


const truckIcon = new L.Icon({
    iconUrl: require('../assets/truck.png'),
    iconAnchor: [5, 0],
    popupAnchor: [0, 0],
    iconSize: [20, 20],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});

const pathIcon = new L.Icon({
    iconUrl: require('../assets/circle.png'),
    iconAnchor: [10, 10],
    popupAnchor: [0, 0],
    iconSize: [20, 20],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});

const goalIcon = new L.Icon({
    iconUrl: require('../assets/finish-flag.png'),
    iconAnchor: [10, 10],
    popupAnchor: [0, 0],
    iconSize: [20, 20],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});

export default class BMap extends Component {

    renderMarkers(markers) {
        return markers.map(
            (marker) => {
                return (
                    <Marker position={[marker.latitude, marker.longitude]} icon={truckIcon} key={marker.id}>
                        <Popup>
                            {marker.user.name} <br/>
                            Dernière mise à jour: {moment(marker.date).tz("Europe/Paris").format('LLL')} <br/>
                            {marker.command && (
                                <>Commande: {marker.command.name}, à {marker.command.address} <br/></>
                            )}
                            <Link to={'/entities/user/' + marker.user.id}>Page du livreur</Link>
                        </Popup>
                    </Marker>
                );
            }
        );
    }

    renderPath() {
        const LatLong = this.props.markers.map(geo => [geo.latitude, geo.longitude]);
        return <AntPath positions={LatLong}/>
    }

    renderAntPath() {
        return (
            <div>
                {this.renderPath()}
                {this.props.markers.map((marker) => {
                    // Retourne les marqueurs au dessus de chaque point du antpath
                    const isGoal = marker.type === 'goal';
                    return (
                        <Marker position={[marker.latitude, marker.longitude]} icon={isGoal ? goalIcon : pathIcon} key={marker.id} >
                            <Popup>
                                {marker.user.name} <br/>
                                Dernière mise à jour: {moment(marker.date).tz("Europe/Paris").format('LLL')} <br/>
                                {marker.command ? <>Commande: {marker.command.name}, à {marker.command.address} <br/></> : null}
                                <Link to={'/entities/user/' + marker.user.id}>Page du livreur</Link>
                            </Popup>
                        </Marker>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <Map center={this.props.position} zoom={13} style={this.props.customStyle}>
                <TileLayer
                    // url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    this.props.renderPath
                        ? this.renderAntPath()
                        : this.renderMarkers(this.props.markers)
                }
                <Control position='topleft'>
                    <button className="map-button" onClick={this.props.onUpdateRequest}>
                        <i className="fa fa-redo"/>
                    </button>
                </Control>
            </Map>
        );
    }

}

BMap.defaultProps = {
    onUpdateRequest: () => {}
};
