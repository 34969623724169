import React, { Component } from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import {updateCommandDelivery} from "../../data/entities/Command";
import {connect} from "react-redux";
import moment from "moment";
import {DatePicker} from "react-forms";

class DeliveryDatePicker extends Component
{
    state = {
        data: this.props.data,
        delivery_time: moment(this.props.delivery_time),
        loading: false
    }

    _submit = () => {
        this.setState({ loading: true });

        let { data } = this.props;
        let delivery_date = this.state.delivery_time.format();

        this.props.updateCommandDelivery(data.dbid, {
            delivery_date
        })
            .then((res) => {
                this.props.update();
                this.setState({ loading: false });
            })
            .catch((err) => {
                console.error(err)
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div>
                <p>
                    <input
                        value={this.state.delivery_time.format("YYYY-MM-DD")}
                        type="date"
                        onChange={e => {
                            const date = moment(e.target.value)
                            this.setState({
                                delivery_time: this.state.delivery_time.set({
                                    date: date.get('date'),
                                    month: date.get('month'),
                                    year: date.get('year')
                                })
                            })
                        }}
                    />

                    <span className="px-3">à</span>

                    <TimePicker
                        defaultValue={this.state.delivery_time}
                        onChange={date => {
                            this.setState({
                                delivery_time: this.state.delivery_time.set({
                                    hour: date.get('hour'),
                                    minute: date.get('minute')
                                })
                            })
                        }}
                        showSecond={false}
                    />
                </p>

                <button
                    disabled={this.state.loading}
                    onClick={this._submit}>
                    Enregistrer
                </button>
            </div>
        )
    }
}

export default connect(null, {updateCommandDelivery})(DeliveryDatePicker);
