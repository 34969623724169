import React, {Component} from 'react';
import {connect} from 'react-redux';
import Calendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {getCommandsForWeek} from "../data/entities/Command";
import RBCToolbar from "../components/calendar/RBCToolbar";
import RBCEvent from "../components/calendar/RBCEvent";
import TrelloCardEditModal from "../components/trello/TrelloCardEditModal";

class CalendarPage extends Component {
    state = {
        events: [],
        loading: true,
        showCommandModal: false,
        cmdModData: null,
        date: new Date
    };

    componentDidMount() {
        this.loadDate(new Date());
    }

    loadDate = (date) => {
        this.setState({loading: true});
        this.props.getCommandsForWeek(date).then((r) => {
            console.log(r.payload.data);

            let items = r.payload.data;
            items.forEach((i) => {
                i.start = moment(i.delivery_position).toDate();
                i.end = moment(i.shippedat || i.delivery_position).toDate();
            });

            this.setState({events: items, loading: false, date})
        });
    };

    onRequestModal = (cmdModData) => {
        console.log("open modal");
        this.setState({cmdModData, showCommandModal: true});
    };

    onModalClose = (data) => {
        this.setState({showCommandModal: false});
        return this.loadDate(this.state.date)
        let index = this.state.events.findIndex(x => x.dbid === data.id);
        if (index >= 0) {
            this.state.events[index].materials = data.materials;
            this.setState({events: this.state.events, showCommandModal: false});
        }
    };

    render() {
        const localizer = Calendar.momentLocalizer(moment); // or globalizeLocalizer
        const defaultView = 'week';

        return (
            <div className="container">
                {this.state.showCommandModal ? <TrelloCardEditModal data={this.state.cmdModData} onClose={this.onModalClose}/> : ''}
                <div>
                    <Calendar
                        defaultView={defaultView}
                        views={[defaultView]}
                        events={this.state.events}
                        localizer={localizer}
                        onNavigate={(date) => {
                            this.loadDate(date)
                        }}
                        onSelectEvent={this.onRequestModal}
                        min={moment({hour: 6, minute: 0, second: 0, millisecond: 0}).toDate()}
                        components={{
                            toolbar: (props) => (<RBCToolbar {...props} loading={this.state.loading}/>),
                            event: (props) => (
                                <RBCEvent
                                    onRequestModal={this.onRequestModal}
                                    {...props}
                                />
                            )}}
                    />
                </div>
            </div>
        )
    }
}

export default connect(({user}) => ({user}), {getCommandsForWeek})(CalendarPage)
