import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import '../styles/sidebar.scss';
import '../styles/generalpurpose/hoverdisplayproperties.scss';
import './BaseLayout.scss';
import {logout} from "../data/OAuth";
import {ROLE_ADMIN} from "../config/security";

class BaseLayout extends Component {
    state = {
        sidebarExpanded: window.lapierre ? window.lapierre.navexpanded : true
    };

    render() {
        return (
            <div className={this.props.user.profile ? "logged BaseLayout wrapper" : "container BaseLayout"}>
                {this.renderTopPanel()}
                {this.renderLeftPanel()}
                <div style={{flex: 1}}>
                    {this.props.children}
                </div>
            </div>
        )
    }

    renderDisconnect = (loggedin) => {
        if (loggedin) {
            return (<div className="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle"
                           href="#"
                           id="dropdown_auth"
                           data-toggle="dropdown"
                        >
                            {this.props.user.profile.email}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item" style={{color: '#676767'}} to="/" onClick={this.props.logout}><i className='fa fa-sign-out-alt mr-1'/> Déconnexion</Link>
                        </div>
                    </li>
                </ul>
            </div>)
        }
    };

    renderTopPanel() {
        return (
            <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
                <div className={this.props.user.profile ? "" : "container"}>
                    <Link className="navbar-brand" style={{paddingLeft: 0}} to="/">
                        <img src={process.env.PUBLIC_URL + '/images/logo-2.png'}/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarsExampleDefault">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    {this.renderDisconnect(!!this.props.user.profile)}
                </div>
            </nav>
        );
    }

    onCollapseSidebar = () => {
        this.setState({sidebarExpanded: !this.state.sidebarExpanded}, () => {
            if (!window.lapierre)
                window.lapierre = {};

            window.lapierre.navexpanded = this.state.sidebarExpanded;
        });
    };

    renderLeftPanel() {
        const isAdmin = this.props.user && this.props.user.profile && this.props.user.profile.role === ROLE_ADMIN;
        const canSchedule = true;
        const canMap = true;
        const canManageUsers = isAdmin;

        if (this.props.user.profile) {
            return (
                <nav id="sidebar" className={this.state.sidebarExpanded ? '' : ' active'}>
                    <ul className={'list-unstyled components'}>
                        <li className="text-center">
                            <i className="fa fa-ellipsis-h hover-cursor-pointer"
                               title="Agrandir/réduire la barre"
                               id="sidebar-toggler"
                               onClick={this.onCollapseSidebar}
                            />
                        </li>
                        {canManageUsers ?
                            <li>
                                <Link to="/entities/user">
                                    <img src={process.env.PUBLIC_URL + '/flaticon/users.png'}/>
                                    <span className="active-hidden">Gestion des utilisateurs</span>
                                </Link>
                            </li> : '' }
                        { canMap ?
                        <li>
                            <Link to="/entities/geolocational">
                                <img src={process.env.PUBLIC_URL + '/flaticon/map-location.png'}/>
                                <span className="active-hidden">Tournée en temps réel</span>
                            </Link>
                        </li> : '' }
                        <li>
                            <Link to="/commands/calendar">
                                <img src={process.env.PUBLIC_URL + '/flaticon/calendar.png'}/>
                                <span className="active-hidden">Calendrier</span>
                            </Link>
                        </li>
                        { canSchedule ?
                        <li>
                            <Link to="/order/schedule">
                                <img src={process.env.PUBLIC_URL + '/flaticon/clipboard.png'}/>
                                <span className="active-hidden">Gestion des tournées</span>
                            </Link>
                        </li> : '' }
                        <li>
                            <Link to="/entities/notification">
                                <img src={process.env.PUBLIC_URL + '/flaticon/notification.png'}/>
                                <span className="active-hidden">Notifications</span>
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*<Link to="/deliveryman/statistiques">*/}
                        {/*<img src={process.env.PUBLIC_URL + '/flaticon/presentation.png'}/>*/}
                        {/*<span className="active-hidden">Statistiques</span>*/}
                        {/*</Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link to="/entities/material">
                                <img src={process.env.PUBLIC_URL + '/flaticon/food.png'}/>
                                <span className="active-hidden">Gestion du matériel</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/entities/vehicle">
                                <img src={process.env.PUBLIC_URL + '/flaticon/delivery-truck.png'}/>
                                <span className="active-hidden">Gestion des véhicules</span>
                            </Link>
                        </li>
                        <li>
                            <span style={{ paddingVertical: 10, paddingLeft: 30, display: 'block', fontSize: 11 }}>
                                Identifiant : {this.props.user.profile.device_id}
                            </span>
                        </li>
                    </ul>
                </nav>
            )

        }

        return "";


    }

    renderEntitiesDropdown() {
        return (
            <div className="dropdown-menu">
                <Link className="dropdown-item" to="/entities/role">Role</Link>
                <Link className="dropdown-item" to="/entities/user">User</Link>
                <Link className="dropdown-item" to="/entities/command">Command</Link>
                <Link className="dropdown-item" to="/entities/product">Product</Link>
                <Link className="dropdown-item" to="/entities/material">Material</Link>
                <Link className="dropdown-item" to="/entities/notification">Notification</Link>
                <Link className="dropdown-item" to="/entities/geolocational">Geolocational</Link>
                <Link className="dropdown-item" to="/entities/vehicle">Vehicle</Link>
                <Link className="dropdown-item" to="/entities/order_status">OrderStatus</Link>
            </div>
        )
    }
}

export default connect(({user}) => ({user}), {logout})(BaseLayout)
