import moment from "moment";
import {request} from '../../helpers'

// Actions

const ENTITY_PRODUCT_INDEX = 'ENTITY_PRODUCT_INDEX'
const ENTITY_PRODUCT_INDEX_SUCCESS = 'ENTITY_PRODUCT_INDEX_SUCCESS'
const ENTITY_PRODUCT_INDEX_FAIL = 'ENTITY_PRODUCT_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_PRODUCT_INDEX, 'get', '/products')
}

export function searchAll(query)
{
    return request(ENTITY_PRODUCT_INDEX, 'post', '/products/search', { query })
}

const ENTITY_PRODUCT_RETRIEVE = 'ENTITY_PRODUCT_RETRIEVE'

export function fetchProduct(id)
{
    return request(ENTITY_PRODUCT_RETRIEVE, 'get', `/products/${id}`)
}

const ENTITY_PRODUCT_CREATE = 'ENTITY_PRODUCT_CREATE'

export function createProduct(data)
{
    return request(ENTITY_PRODUCT_CREATE, 'post', '/products', data)
}

const ENTITY_PRODUCT_UPDATE = 'ENTITY_PRODUCT_UPDATE'

export function updateProduct(id, data)
{
    return request(ENTITY_PRODUCT_UPDATE, 'patch', `/products/${id}`, data)
}


const ENTITY_PRODUCT_DELETE = 'ENTITY_PRODUCT_DELETE'

export function deleteProduct(id)
{
    return request(ENTITY_PRODUCT_DELETE, 'delete', `/products/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityProductReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_PRODUCT_INDEX:
            return {...state, loading: true}
        case ENTITY_PRODUCT_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_PRODUCT_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}