export function request(type, method, url, data = {}, meta = {}) {
    return {
        type: type,
        payload: {
            request: {
                url: url,
                method: method,
                data: data
            }
        },
        ...meta
    }
}