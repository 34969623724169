import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';

import Modal from "../../../components/Modal";

import {createUser} from "../../../data/entities/User";
import {fetchAll} from "../../../data/entities/Role";

class UserCreateModal extends Component {
    state = {
        _formData: null,
        _loading: true,
        _roles: [{id: 0, name: 'Chargement..'}],
        phone: "",
        business_name: "",
        zipcode: "",
        city: "",
        delivery_address: "",
        billing_address: "",
        email: "",
        firstname: "",
        lastname: "",
        role: 0
    };

    _submit = () => {
        this.setState({_loading: true})

        const data = {...this.state};
        delete data._loading;
        delete data._formData;

        this.props.createUser(data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message}/>);
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({_formData: e.error.response.data});
                this.setState({_loading: false})
            })
    };

    componentDidMount() {
        this.props.fetchAll().then((r) => {
            this.setState({_roles: r.payload.data, role: r.payload.data[0].id, _loading: false})
        })
    }

    render() {
        return (
            <Modal title={"Nouvel utilisateur"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <Input
                        value={this.state.phone} onChangeValue={(phone) => this.setState({phone})}
                        placeholder="Téléphone" label="Téléphone" type="text"/>

                    <Input
                        value={this.state.business_name}
                        onChangeValue={(business_name) => this.setState({business_name})}
                        placeholder="Nom de l'entreprise" label="Nom de l'entreprise" type="text"/>

                    <div className='form-row'>
                        <div className='col'>
                            <Input
                                value={this.state.city} onChangeValue={(city) => this.setState({city})}
                                placeholder="Ville" label="Ville" type="text"/>
                        </div>
                        <div className='col'>
                            <Input
                                value={this.state.zipcode} onChangeValue={(zipcode) => this.setState({zipcode})}
                                placeholder="Code postal" label="Code postal" type="text"/>
                        </div>
                    </div>

                    <Input
                        value={this.state.delivery_address}
                        onChangeValue={(delivery_address) => this.setState({delivery_address})}
                        placeholder="Adresse de livraison" label="Adresse de livraison" type="text"/>

                    <Input
                        value={this.state.billing_address}
                        onChangeValue={(billing_address) => this.setState({billing_address})}
                        placeholder="Adresse de facturation" label="Adresse de facturation" type="text"/>

                    <Input
                        value={this.state.email} onChangeValue={(email) => this.setState({email})}
                        placeholder="Email *" label="Email *" type="email"/>

                    <div className='form-row'>
                        <div className='col'>

                            <Input
                                value={this.state.firstname} onChangeValue={(firstname) => this.setState({firstname})}
                                placeholder="Prénom" label="Prénom" type="text"/>
                        </div>
                        <div className='col'>
                            <Input
                                value={this.state.lastname} onChangeValue={(lastname) => this.setState({lastname})}
                                placeholder="Nom" label="Nom" type="text"/>
                        </div>
                    </div>


                    {this.renderRoles(this.state._roles)}

                    <br/>

                    <span className='text-muted d-flex'>
                        <i className='fa fa-info-circle pt-1 pr-1'/>
                        <em>Une fois le profil créé, vous pourrez définir un mot de passe dans l'édition du profil pour permettre la connexion</em>
                    </span>

                    <br/>

                    <Submit disabled={this.state._loading}><i className='fa fa-check mr-1'/> Créer l'utilisateur</Submit>
                </Form>
            </Modal>
        )
    }

    renderRoles = (roles) => {
        const jsxs = roles.map((r) => {
            return (<option key={r.id} value={r.id}>{r.name}</option>)
        });

        return <div className="form-group">
            <label htmlFor="role-select">Role :</label>
            <select id="role-select" className="form-control" onChange={(e) => this.setState({role: e.target.value})}>
                {jsxs}
            </select>
        </div>;
    }
}

export default connect(null, {createUser, fetchAll})(UserCreateModal)
