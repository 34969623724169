import React, {Component} from 'react';
import {connect} from 'react-redux';
import {APP_NAME} from "../config/parameters";
import {Helmet} from "react-helmet";
import Trello from '../components/trello/Trello';


class SchedulePage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>{APP_NAME}</title>
                </Helmet>
                <div className="container">
                    <Trello/>
                </div>
            </div>
        )
    }
}

export default connect(({user}) => ({user}), null)(SchedulePage)
