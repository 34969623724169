import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import VehicleCreateModal from "./VehicleCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/Vehicle'
import VehicleDeleteModal from "./VehicleDeleteModal";

class VehicleIndexPage extends Component
{
    state = {
        search: '',
        showVehicleCreateModal: false,
        showVehicleDeleteModal: false,
        entity: null
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    toggleDeleteModal(entity = null) {
        if (entity)
            this.setState({ entity, showVehicleDeleteModal: true })
        else
            this.setState({ entity: null, showVehicleDeleteModal: false })
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Véhicules</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <h1>Véhicules</h1>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.vehicle_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button className="ml-3" onClick={() => this.setState({ showVehicleCreateModal: true })}><i className="fa fa-plus mr-1"/> Ajouter un vehicule</button>
                    </div>
                </div>
                {this.renderEntities()}
                {this.state.showVehicleCreateModal ? (
                    <VehicleCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showVehicleCreateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showVehicleDeleteModal ? (
                    <VehicleDeleteModal
                        data={this.state.entity}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.setState({ showVehicleDeleteModal: false }, () => this._fetch())
                            }
                            else this.toggleDeleteModal()
                        }}
                    />
                ) : null}
            </div>
        )
    }

    renderEntities()
    {
        if (this.props.vehicle_entity.loading && !this.props.vehicle_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.vehicle_entity.index.length) {
            return <Empty/>
        }

        return (
            <table className="table table-striped mt-3">
                {this.props.vehicle_entity.index.map((entity, i) => (
                    <tr key={i}>
                        <td>
                            <Link to={"/entities/vehicle/" + entity.id}>Véhicule n°{entity.id} - {entity.name}</Link>
                        </td>
                        <td className='text-right'>
                            <button onClick={() => this.toggleDeleteModal(entity)}><i className='fa fa-trash mr-2'/> Supprimer</button>
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default connect(({ vehicle_entity }) => ({ vehicle_entity }), { fetchAll, searchAll })(VehicleIndexPage)
